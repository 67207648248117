import React from "react";
import Button from "@mui/material/Button";
import {Collapse} from "@mui/material";

export default function ShowMore({initialExpanded = false, children}: {
  initialExpanded?: boolean,
  children: React.ReactNode
}) {
  const [isExpanded, setIsExpanded] = React.useState(initialExpanded);
  // show children if expanded
  const renderShowButton = () => (
    <Button variant="text" color="primary"
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{fontSize: 11, ml: 1}}
    >
      {isExpanded ? "Show Less" : "Show More"}
    </Button>
  )
  return (
    <>
      {renderShowButton()}
      <Collapse in={isExpanded}>
        {isExpanded && children}
        {renderShowButton()}
      </Collapse>

    </>
  )
}
