// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import React, {useContext, useEffect, useState} from "react";
// auth
import {AuthContext} from "../../auth/context/jwt";
import {useCredits} from "../../providers/credits-provider";
// styles
import './remaining-credits.css';

export default function RemainingCredits() {
  const {user, authenticated} = useContext(AuthContext);
  const {userCredits} = useCredits();
  const [prevCredits, setPrevCredits] = useState(userCredits.credits);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (userCredits.credits < prevCredits) {
      setPrevCredits(userCredits.credits);
      setAnimate(false); // Reset animation state
      setTimeout(() => setAnimate(true), 0); // Delay setting animate to true
      const timer = setTimeout(() => setAnimate(false), 3000);
      return () => clearTimeout(timer);
    }
    setPrevCredits(userCredits.credits); // Update the previous credits even if no animation
    return undefined; // Ensure a value is always returned
  }, [userCredits.credits, prevCredits]);

  if (!authenticated) {
    return null;
  }

  return (
    <Tooltip title={`Next renewal: ${new Date(userCredits.next_renewal).toLocaleDateString()}`}>
      <Box sx={{cursor: "pointer"}}>
        <Typography variant="body2" sx={{color: "text.secondary"}} textAlign="center" noWrap>
          AI credits
        </Typography>
        <Typography
          variant="body2"
          sx={{fontSize: 13, fontWeight: 700}}
          textAlign="center"
          noWrap
          className={animate ? 'animate-credits' : ''}
        >
          {userCredits.credits.toFixed(2)}
        </Typography>
      </Box>
    </Tooltip>
  );
}
