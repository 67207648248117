import React from "react";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import FeaturesPreviewMobile from "./features-preview-mobile";
import FeaturesPreviewDesktop from "./features-preview-desktop";


export default function FeaturesPreview() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <>
      {isMobile ? <FeaturesPreviewMobile/> : <FeaturesPreviewDesktop/>}
    </>
  );
}
