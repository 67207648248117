import React from "react";
import {CircularProgress} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function AiProgressbar({text = ""}: {text?: string}) {
  const textToShow = text !== "" ? text : "AI analysis loading... Hang tight!";
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" sx={{mb: 3}}>
      <Typography variant="body1">
        {textToShow}
      </Typography>
      <CircularProgress/>
    </Stack>
  );
}
