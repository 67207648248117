import React, {useEffect, useRef} from "react";
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import ScoreBreakdownProgressElement from "src/components/company/score-breakdown-progress-element";
import {ScoredCompanyList} from "../../components/sector-companies/types";
import {marketCapFormatter} from "../../utils/formatting";
import {defaultGrowthScore} from "../../components/company/constants";


type CompanyTableProps = {
    companies: ScoredCompanyList[];
    onCompanyClick: (ticker: string, newTab: boolean) => void;
};

const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});


export default function CompanyTable({companies, onCompanyClick}: CompanyTableProps) {
   const dataGridRef = useRef<HTMLDivElement>(null); // Define the type of the ref

    const columnsMobile: GridColDef[] = [
        {field: 'ticker', headerName: 'Ticker', flex: 1, align: 'center', headerAlign: 'center'},
        {field: 'company_name', headerName: 'Company Name', flex: 1.2, align: 'center', headerAlign: 'center'},
        {field: 'score', headerName: 'Score', flex:1, align: 'center', headerAlign: 'center', valueFormatter: ({value}) => numberFormatter.format(value)}
    ]
    const columns: GridColDef[] = [
        {field: 'ticker', headerName: 'Ticker', flex: 1, align: 'center', headerAlign: 'center'},
        {field: 'company_name', headerName: 'Company Name', flex: 1.2, align: 'center', headerAlign: 'center'},
        {field: 'sector', headerName: 'Sector', flex: 1.2, align: 'center', headerAlign: 'center'},
        {field: 'market_cap', headerName: 'Market Cap', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: ({value}) => marketCapFormatter(value)},
        {field: 'country', headerName: 'Country', flex: 1, align: 'center', headerAlign: 'center'},
        {field: 'score', headerName: 'Score', flex: 0.8, align: 'center', headerAlign: 'center', valueFormatter: ({value}) => numberFormatter.format(value)},
        {
            field: 'price_data',
            headerName: 'Valuation',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => params.row.score_data?.price_data?.score,
            valueFormatter: ({value}) => numberFormatter.format(value),
            renderCell: (params) => (
                <ScoreBreakdownProgressElement score={params.value} widthParam='50px' heightParam='8px'/>
            )
        },
        {
            field: 'solvency_data',
            headerName: 'Financial Strength',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => params.row.score_data?.solvency_data?.score,
            valueFormatter: ({value}) => numberFormatter.format(value),
            renderCell: (params) => (
                <ScoreBreakdownProgressElement score={params.value} widthParam='50px' heightParam='8px'/>
            )
        },
        {
            field: 'efficiency_data',
            headerName: 'Efficiency',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => params.row.score_data?.efficiency_data?.score,
            valueFormatter: ({value}) => numberFormatter.format(value),
            renderCell: (params) => (
                <ScoreBreakdownProgressElement score={params.value} widthParam='50px' heightParam='8px'/>
            )
        },
        {
            field: 'growth_data',
            headerName: 'Growth',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => params.row.score_data.growth_data ? params.row.score_data?.growth_data?.score : defaultGrowthScore,
            valueFormatter: ({value}) => numberFormatter.format(value),
            renderCell: (params) => (
                <ScoreBreakdownProgressElement score={params.value} widthParam='50px' heightParam='8px'/>
            )
        },
    ];

    const handleRowClick = (params: any, event: any) => {
        if (event.ctrlKey || event.metaKey || event.button === 1) { // Check if Ctrl or Cmd key is pressed or middle mouse button is clicked
            // Open a new tab with the desired URL. Replace 'yourURL' with the actual URL you want to open
            onCompanyClick(params.row.ticker, true);
        } else {
            onCompanyClick(params.row.ticker, false);
        }
    };
  // eslint-disable-next-line consistent-return
    useEffect(() => {
        const gridElement = dataGridRef.current;
        if (gridElement) {
            const handleMiddleClick = (event: MouseEvent) => {
                if (event.button === 1) { // Middle mouse button is 1
                    // Use type assertion for event.target
                    const target = event.target as HTMLElement;
                    if (target) {
                        const rowElement = target.closest('.MuiDataGrid-row');
                        if (rowElement) {
                            // Add radix parameter to parseInt
                            const rowIndex = parseInt(rowElement.getAttribute('data-rowindex') ?? '0', 10);
                            const row = companies[rowIndex];
                            if (row) {
                                onCompanyClick(row.ticker, true);
                            }
                        }
                    }
                }
                // No return statement needed as this is an event handler
            };

            gridElement.addEventListener('mousedown', handleMiddleClick);

            return () => {
                gridElement.removeEventListener('mousedown', handleMiddleClick);
            };
        }
    }, [companies, onCompanyClick]);

    return (
        <div id="company-table" style={{width: '100%'}} ref={dataGridRef}>
            <DataGrid
                rows={companies}
                getRowId={(row) => row.ticker}
                columns={columns}
                onRowClick={(params, event) => handleRowClick(params, event)} // Update this line
                initialState={{
                    pagination: {
                        paginationModel: {page: 0, pageSize: 10},
                    },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block',
                        lg: 'block',
                        xl: 'block'
                    },
                    '& .MuiDataGrid-main': {
                      borderRadius: 2,
                    },
                    '& .headerWrap': {
                        whiteSpace: 'normal !important', // Override MUI default and allow wrapping
                        textOverflow: 'clip',
                        lineHeight: 1.5, // Adjust the line-height as needed
                    },
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer', // Add this line to change the cursor on hover
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'pre-line', // Allow text to wrap at newline characters
                        lineHeight: 1.2,        // Adjust the line-height as needed
                    }
                }}
            />

            {/*
            Mobile view
            */}
<DataGrid
                rows={companies}
                getRowId={(row) => row.ticker}
                columns={columnsMobile}
                onRowClick={handleRowClick} // Add this line to handle row clicks
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                sx={{
                    fontSize: '12px',
                    display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'none',
                        lg: 'none',
                        xl: 'none'
                    },
                    '& .MuiDataGrid-main': {
                      borderRadius: 2,
                    },
                    '& .headerWrap': {
                        whiteSpace: 'normal !important',
                        textOverflow: 'clip',
                        lineHeight: 1.5,
                    },
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontSize: '0.8rem',
                        whiteSpace: 'pre-line', // Allow text to wrap at newline characters
                        lineHeight: 1.2,        // Adjust the line-height as needed
                    },
                }}
            />

        </div>
    );
}
