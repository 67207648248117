import {Box} from "@mui/material";
import Stack from "@mui/material/Stack";
import CustomHeader from "./custom-header";
import {CreditsProvider} from "../../providers/credits-provider";

type Props = {
  children: React.ReactNode;
};

export function RouteWithHeader({children}: Props) {
  return (
    <CreditsProvider>
      <Stack direction="column">
        <Box>
          <CustomHeader/>
        </Box>
        <Box>
          {children}
        </Box>
      </Stack>
    </CreditsProvider>
  );
}
