import {Helmet} from "react-helmet-async";
// layouts
import CompanyDetail from "../../components/company/company-detail";
import {RouteWithHeader} from "../../layouts/custom-header/route-with-header";

export const companyRoutes = [
  {
    path: 'companies/:ticker',
    element: (
      <>
        <Helmet>
          <title>ValueHunter: Company Detail</title>
        </Helmet>
        <RouteWithHeader>
          <CompanyDetail/>
        </RouteWithHeader>
      </>
    ),
  },
];
