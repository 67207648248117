import React, {useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import ReactMarkdown from "react-markdown";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import axios, {endpoints} from "../../utils/axios";
import AiProgressbar from "../utility_components/ai-progressbar";
import Iconify from "../iconify";


export interface EarningsCallTranscript {
  ticker: string;
  year: number;
  quarter: number;
  content: string;
}

interface CompanyDocumentsProps {
  ticker: string | undefined;
  earnings_call_transcript: EarningsCallTranscript | undefined;
}


export default function EarningsCallAiAnalysis({
                                                 ticker,
                                                 earnings_call_transcript
                                               }: CompanyDocumentsProps) {
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [earningsCallAnalysis, setEarningsCallAnalysis] = useState<any>('');


  const theme = useTheme();
  const customScrollbarStyles = {
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '8px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.grey[600]
    },
  };

  const formatTranscript = (transcript: string): string => {
    // split the transcript by new \n
    const formattedTranscript = transcript.split("\n")
      .map((line) => line.replace(/([^:]+)(:\s)/g, '<strong>$1</strong>$2'))
      .join("<br><br>");
    return formattedTranscript;
  }

  if (earnings_call_transcript && earnings_call_transcript.content) {
    earnings_call_transcript.content = formatTranscript(earnings_call_transcript.content);
  }


  useEffect(() => {
    const getEarningsCallAiAnalysis = async () => {
      if (!earnings_call_transcript) {
        return;
      }
      try {
        setIsLoadingAnalysis(true);
        const analysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=earnings_call`);
        setEarningsCallAnalysis(analysisResponse.data.answer.answer);
      } catch (error) {
        console.error("Error fetching company earnings call AI analysis:", error);
      }
      setIsLoadingAnalysis(false);
    }
    getEarningsCallAiAnalysis();

  }, [ticker, earnings_call_transcript]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={() => setDialogOpen(true)} sx={{width: "180px"}}>
            <Box sx={{display: 'flex', alignItems: 'left', width: '100%'}}>
              <Iconify icon="material-symbols-light:call-log-outline" sx={{mr: 1}}/>
              <span>Full Document</span>
            </Box>
          </Button>
        </Grid>
        <Grid item xs={12} md={12} xl={12} sx={{mb: 1}}>
          {isLoadingAnalysis && <AiProgressbar/>}
            <Box
              sx={{
                maxHeight: '400px', // Adjust this value to fit exactly 5 lines of text
                overflowY: 'auto', // Enable vertical scrolling
                ...customScrollbarStyles
              }}
            >
              <Typography variant="body2">
              <ReactMarkdown>{earningsCallAnalysis}</ReactMarkdown>
              </Typography>
            </Box>

        </Grid>


      </Grid>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Iconify icon="material-symbols-light:call-log-outline"/>
            <Typography variant="h6">
              Earnings Call
            </Typography>
          </Stack>

          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setDialogOpen(false)}
            aria-label="close"
            sx={{position: 'absolute', right: 14, top: 8}}
          >
            <CloseIcon/>
          </IconButton>
          <Typography variant="body2" sx={{mt: 1, color: "text.secondary"}}>
            Q{earnings_call_transcript?.quarter} | {earnings_call_transcript?.year}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{whiteSpace: "pre-line"}}>
          <div
            dangerouslySetInnerHTML={{__html: earnings_call_transcript?.content || ""}}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Got it!</Button>
        </DialogActions>
      </Dialog>
    </>

  )
}
