import React, {useEffect, useState} from "react";
import {Box, Button, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import {Link} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import {shouldUseLocalLogo} from "../company/constants";
import PriceChartMinimal from "../price-chart/price-chart-minimal";
import PercentageChange from "../price-chart/percentage-change";
import {LoginToAccess} from "../utility_components/login-to-access";
import {BecomePremiumAccess} from "../utility_components/become-premium-access";
import NewsAnalysis from "../company/news_analysis";
import {useBoolean} from "../../hooks/use-boolean";
import Iconify from "../iconify";
import CustomSearchbar from "../../layouts/_common/custom-searchbar/custom-searchbar";
import AiProgressbar from "../utility_components/ai-progressbar";
import ScoreBreakdownProgressElement from "../company/score-breakdown-progress-element";
import {useAuthContext} from "../../auth/hooks";


const tableFontSize = "0.9rem";

const defaultCompany = {
  symbol: "",
  companyName: "",
  companyOutlook: {
    profile: {
      image: ""
    }
  },
  priceLast3Months: []
}

export default function WatchlistMobile({
                                          companies,
                                          onAddCompany,
                                          onRemoveCompany,
                                          isLoading = false
                                        }: {
  companies: any[],
  onAddCompany: any,
  onRemoveCompany: any,
  isLoading?: boolean
}) {
  const theme = useTheme();
  const [currentCompany, setCurrentCompany] = React.useState(defaultCompany);
  const [dialogOpen, setDialogOpen] = useState(false);
  const {authenticated} = useAuthContext();

  useEffect(() => {
    if (currentCompany.symbol === "" && companies.length > 0) {
      setCurrentCompany(companies[0]);
    }
  }, [companies, currentCompany]);

  const renderMobileTable = () => (
    <Stack sx={{mb: 2, width: '100%'}}>
      {/* @ts-ignore */}
      {companies && companies.length > 0 && companies.map((company, index) => (
        <Box
          key={index}
          // onClick={() => navigate(`/companies/${company.symbol}`)}
          sx={{
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: theme.palette.action.hover,
            },
            backgroundColor: index % 2 === 0 ? theme.palette.background.default : theme.palette.action.selected,
            p: 2,
          }}
        >
          <Stack direction="column">
            {/* logo, name and ticker */}

            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="center">
                <Box component="img"
                     src={shouldUseLocalLogo.has(company.symbol) ? `../../assets/images/company_logos/${company.symbol}.png` : company.companyOutlook.profile.image}
                     sx={{maxWidth: 40, maxHeight: 40}}/>
                <Stack direction="column" sx={{ml: 1}}>
                  <Typography sx={{fontSize: tableFontSize, fontWeight: 'bold'}}>
                    {company.companyName}
                  </Typography>
                  <Typography sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
                    {company.symbol}
                  </Typography>
                </Stack>
              </Stack>

              <Box>
                <Button
                  color="inherit"
                  variant="text"
                  sx={{borderColor: 'text.primary'}}
                  component={Link}
                  to={`/companies/${company.symbol}`}
                  endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={20}/>}
                >
                  View Company
                </Button>
              </Box>
            </Stack>

            {/* price, score, chart */}
            {company.priceLast3Months?.length > 0 && (
              <Grid container spacing={2} sx={{mt: 1}}>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{
                    fontSize: tableFontSize,
                    color: 'text.secondary',
                  }}> Price </Typography>
                  <Typography variant="body2"
                              sx={{fontSize: tableFontSize}}>${`${company.priceLast3Months[0][1].toFixed(2)}`}</Typography>
                  <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
                    7 Days Change
                  </Typography>
                  {company.priceLast3Months?.length > 6 && (
                    <PercentageChange leastRecentPrice={company.priceLast3Months[6][1]}
                                      mostRecentPrice={company.priceLast3Months[0][1]}
                                      fontSize={tableFontSize}/>
                  )}
                  <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
                    3 Months Change
                  </Typography>
                  {company.priceLast3Months?.length > 40 && (
                    <PercentageChange
                      leastRecentPrice={company.priceLast3Months[company.priceLast3Months.length - 1][1]}
                      mostRecentPrice={company.priceLast3Months[0][1]} fontSize={tableFontSize}/>
                  )}

                  <Typography variant="body2" sx={{
                    fontSize: tableFontSize,
                    color: 'text.secondary',
                    mt: 1
                  }}>Last 3 Months</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>Overall
                    Score</Typography>
                  <Typography variant="body2" fontWeight="bold"
                              sx={{fontSize: tableFontSize}}>{company.score?.toFixed(2)}</Typography>
                  <Typography variant="body2" sx={{
                    fontSize: tableFontSize,
                    color: 'text.secondary'
                  }}>Breakdown</Typography>

                  <Stack direction="column" spacing={0.1}>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Typography sx={{fontSize: 10}}>V</Typography>
                      <ScoreBreakdownProgressElement score={company.score_data?.price_data?.score}
                                                     heightParam="8px" widthParam="40px"
                                                     sx={{mx: 0.2}}/>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Typography sx={{fontSize: 10}}>F</Typography>
                      <ScoreBreakdownProgressElement score={company.score_data?.solvency_data?.score}
                                                     heightParam="8px" widthParam="40px"
                                                     sx={{mx: 0.2}}/>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Typography sx={{fontSize: 10}}>E</Typography>
                      <ScoreBreakdownProgressElement score={company.score_data?.efficiency_data?.score}
                                                     heightParam="8px" widthParam="40px"
                                                     sx={{mx: 0.2}}/>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Typography sx={{fontSize: 10}}>G</Typography>
                      <ScoreBreakdownProgressElement score={company.score_data?.growth_data?.score}
                                                     heightParam="8px" widthParam="40px"
                                                     sx={{mx: 0.2}}/>
                    </Stack>
                  </Stack>


                </Grid>
              </Grid>

            )}

            <Stack direction="column" alignItems="center" justifyContent="center">
              <PriceChartMinimal prices={company.priceLast3Months} width="100%" height="80%"/>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>

              <Button
                color="inherit"
                variant="outlined"
                sx={{borderColor: 'text.primary'}}
                startIcon={<Iconify icon="eva:file-text-outline" width={20}/>}
                onClick={() => {
                  setCurrentCompany(company);
                  setDialogOpen(true);
                }}
              >
                News Summary
              </Button>
                            <Button
                color="error"
                variant="text"
                onClick={() => {
                  onRemoveCompany(company.symbol);
                }}
              >
                Remove
              </Button>
            </Stack>
          </Stack>
        </Box>
      ))}
    </Stack>
  );

  const renderNews = () => (
    <>
      <Box className="news-summary" sx={{mb: 3}}>
        <LoginToAccess returnTo={`/companies/${currentCompany.symbol}`}
                       featureDescription="the latest news analysis"
                       imageUrl="/assets/images/blurred/news.png"
        >
          <BecomePremiumAccess ticker={currentCompany.symbol}
                               returnTo={`/companies/${currentCompany.symbol}`}
                               featureDescription="the latest news analysis"
                               imageUrl="/assets/images/blurred/news.png"

          >
            <NewsAnalysis ticker={currentCompany.symbol}/>
          </BecomePremiumAccess>
        </LoginToAccess>
      </Box>
    </>
  );

  const renderDialog = () => (
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Iconify icon="eva:file-text-outline"/>
          <Typography variant="h6">
            Latest News Summary
          </Typography>
        </Stack>

        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setDialogOpen(false)}
          aria-label="close"
          sx={{position: 'absolute', right: 14, top: 8}}
        >
          <CloseIcon/>
        </IconButton>

      </DialogTitle>
      <DialogContent sx={{whiteSpace: "pre-line"}}>
        {renderNews()}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  const renderSearchButton = (search: ReturnType<typeof useBoolean>) => (
    <Button
      color="inherit"
      variant="outlined"
      startIcon={<Iconify icon="eva:plus-fill" width={20}/>}
      onClick={() => search.onTrue()}
      sx={{borderColor: 'text.primary'}}
    >
      Add to Watchlist
    </Button>
  );

  const handleCompanyAddClick = async (ticker: string, onDialogClose: () => void) => {
    await onAddCompany(ticker);
    onDialogClose();
  }

  if (companies.length <= 0 && !isLoading && authenticated  ) {
    return (
      <Stack direction="column" alignItems="center">
        <Typography variant="body2" textAlign="center">Nothing here!</Typography>
        <Typography variant="body2" textAlign="center">Add companies to your watchlist to track their performance.</Typography>
        <Box sx={{mt: 2}}>
          <CustomSearchbar renderSearchButton={renderSearchButton}
                           handleCompanyClickCustom={handleCompanyAddClick}
                           tickersToHide={companies.map(c => c.symbol)}/>
        </Box>
      </Stack>
    );
  }

  const renderWatchlist = () => (
    <>
           <Box sx={{my: 1}}>
        <CustomSearchbar renderSearchButton={renderSearchButton}
                         handleCompanyClickCustom={handleCompanyAddClick}
                         tickersToHide={companies.map(c => c.symbol)}/>
      </Box>

      {isLoading && (
        <AiProgressbar text="Loading..."/>
      )}
      {renderMobileTable()}
      {renderDialog()}
    </>
  );

  return (
    <>
      <Typography variant="body2" sx={{color: "text.secondary"}}>Your companies of
        interest with the latest news summarized.</Typography>
            <LoginToAccess returnTo="/dashboard" featureDescription="the watchlist" >
        {renderWatchlist()}
      </LoginToAccess>
    </>
  );
}
