import React, {useCallback, useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {Button, Grid, Link as MuiLink} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReactMarkdown from "react-markdown";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios, {endpoints} from "../../utils/axios";
import {useAuthContext} from "../../auth/hooks";
import {customScrollbarStyles} from "../../theme/css";
import AnnualReportAnalysisDialog from "./annual-report-analysis-dialog";
import CustomSearchbar from "../../layouts/_common/custom-searchbar/custom-searchbar";
import {useBoolean} from "../../hooks/use-boolean";
import Iconify from "../iconify";
import {shouldUseLocalLogo} from "../company/constants";
import {CompanyDocumentsType, ScoredCompany2} from "../company/types";
import AnnualReportContent from "./annual-report-content";
import AiProgressbar from "../utility_components/ai-progressbar";
import ShowMore from "../utility_components/show-more";

interface SummaryType {
  questions: {
    summary: string,
    concerns: string[],
    strengths: string[],
  }
}

interface AnalysisType {
  html: string,
  business: any,
  business_summary: SummaryType,
  risk_factors: any,
  risk_factors_summary: SummaryType,
  mdna: any,
  mdna_summary: SummaryType,
  financials: any,
  financials_summary: SummaryType,
  summary: {
    questions: {
      summary: string,
      concerns: string[],
      strengths: string[],
    }
  }
}

export default function AnnualReportAnalysis() {
  const {ticker} = useParams<{ ticker?: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const emptySummary = {
    questions: {
      summary: "",
      concerns: [],
      strengths: [],
    }
  }

  const [analysis, setAnalysis] = useState<AnalysisType>({
    html: '',
    business: {},
    business_summary: emptySummary,
    risk_factors: {},
    risk_factors_summary: emptySummary,
    mdna: {},
    mdna_summary: emptySummary,
    financials: {},
    financials_summary: emptySummary,
    summary: {
      questions: {
        summary: "",
        concerns: [],
        strengths: [],
      }
    }
  });
  const [loading, setLoading] = useState(false);
  const {authenticated} = useAuthContext();
  const [currentSourceId, setCurrentSourceId] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const [company, setCompany] = useState<ScoredCompany2 | null>(null)
  const [documents, setCompanyDocuments] = useState<CompanyDocumentsType | null>(null);
  const navigate = useNavigate();

  const [currentLocationHash, setCurrentLocationHash] = useState('business');

  const getAnalysis = useCallback(async (tick: string) => {

    try {
      setLoading(true);
      const result = await axios.get<any>(`${endpoints.document_analysis}/${tick}`);
      setAnalysis(result.data);

    } catch (error) {
      console.error("Error fetching annual report analysis", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (authenticated && ticker) {
      getAnalysis(ticker);
    }
  }, [getAnalysis, authenticated, ticker]);

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        const result = await axios.get<any>(`${endpoints.companies.get}/${ticker}`);
        setCompany(result.data);
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    const getCompanyDocuments = async () => {
      try {
        const result = await axios.get<any>(`${endpoints.companies.documents}/${ticker}`);
        setCompanyDocuments(result.data);
      } catch (error) {
        console.error('Error fetching company documents:', error);
      }
    };

    if (ticker) {
      getCompanyData();
      getCompanyDocuments();
    }
  }, [ticker]);


  if (loading) {
    return (
      <Box sx={{mt: 5}}>
        <AiProgressbar text="Generating Annual Report Analysis..."/>

      </Box>
    )
  }

  const renderSources = (sources: string[], sourcesPrefix: string) => sources.map((source, idx) => (
    <Typography
      key={`${source}-${idx}`}
      variant="caption"
      onClick={() => {
        setCurrentSourceId(sourcesPrefix + source);
        setDialogOpen(true);
      }}
      sx={{
        cursor: 'pointer',
        color: theme.palette.primary.main,
        display: 'inline',
        verticalAlign: 'super',
        fontSize: '0.85rem', // You can adjust the font size if needed
        '&:not(:last-of-type)': {
          '&:after': {
            content: '","',
          },
        },
      }}
    >{idx + 1}</Typography>
  ));

  const renderCompanyHeader = (comp: ScoredCompany2) => {
    const path = `../../assets/images/company_logos/${comp.ticker}.png`;
    return (
      <Stack direction="row"
             spacing={2}
             sx={{width: '100%'}}>
        <Stack direction="row">
          <Box>
            <Box
              component="img"
              sx={{
                maxHeight: 60,
                maxWidth: 60,
              }}
              alt="Company logo"
              src={shouldUseLocalLogo.has(comp.ticker) ? path : comp.image_url}
            />
          </Box>
          <Box sx={{ml: '20px'}}>
            <Box>
              <Typography id="company-info" variant="h5" sx={{color: 'text.primary', mb: 1}}>
                {comp.company_name}
              </Typography>
              <Typography variant="body1" sx={{color: 'text.secondary'}}>
                ${comp.ticker}
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Box>
          <Button
            color="inherit"
            variant="outlined"
            sx={{borderColor: 'text.primary', minWidth: 155}}
            startIcon={<Iconify icon="eva:arrow-forward-fill" width={24}/>}
            component={Link}
            to={`/companies/${comp.ticker}`}
          >
            View Company
          </Button>
        </Box>
      </Stack>)
  }

  const renderReportHeader = () => (
    <Stack direction="column"
           sx={{width: '100%'}}>
      <Box>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>Document Type</Typography>
        <Typography variant="body2">10-K</Typography>
      </Box>
      <Box>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>For the Fiscal Year
          Ended</Typography>
        {/* @ts-ignore */}
        <Typography variant="body2">{analysis?.document_date}</Typography>
      </Box>
      <Box>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>Original Document</Typography>
        {/* @ts-ignore */}
        <MuiLink href={documents?.latest_annual_url} target="_blank"
                 sx={{fontSize: "0.875rem"}}>Open</MuiLink>
      </Box>

    </Stack>);

  const renderSummary = (header: string, summary: string, concerns: string[], strengths: string[]) => (
    <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
      <Box sx={{width: "100%"}}>
        <Typography variant="h6">📋 {header}</Typography>
        <Typography variant="body2">
          {summary}
        </Typography>
      </Box>

      <Stack direction="column" spacing={0.5}
             sx={{mt: 1, width: "100%", backdropFilter: 'blur(10px)'}}>
        <Typography variant="h6">Concerns</Typography>
        {concerns && concerns?.map((text, index) => (
          <Box key={index}>
            <Stack direction="row" spacing={0.8}>
              <Box component="span" sx={{color: 'text.secondary'}}>
                <Iconify
                  width={24}
                  icon='ic:twotone-error'
                  sx={{
                    color: 'error.main',
                  }}/>
              </Box>
              <Box component="span" sx={{color: 'text.secondary'}}>
                <Typography variant="body2" sx={{
                  color: 'text.secondary',
                }}>
                  {text}
                </Typography>
              </Box>
            </Stack>
          </Box>
        ))}
      </Stack>


      <Stack direction="column" spacing={0.5}
             sx={{mt: 1, width: "100%", backdropFilter: 'blur(10px)'}}>
        <Typography variant="h6">Strengths</Typography>
        {strengths && strengths?.map((text, index) => (
          <Box key={index}>
            <Stack direction="row" spacing={0.8}>
              <Box component="span" sx={{color: 'text.secondary'}}>
                <Iconify
                  width={24}
                  icon='icon-park-twotone:success'
                  sx={{
                    color: 'success.main',
                  }}/>
              </Box>
              <Box component="span" sx={{color: 'text.secondary'}}>
                <Typography variant="body2" sx={{
                  color: 'text.secondary',
                }}>
                  {text}
                </Typography>
              </Box>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Stack>
  );


  const handleCompanyAddClick = async (tick: string, onDialogClose: () => void) => {
    // setTicker(tick);
    navigate(`/document-analysis/${tick}`);
  }

  const renderNavigationDesktop = (
    <Stack direction={isMobile ? "column" : "row"} spacing={2} alignItems="center" justifyContent="center">
      <Button
        size="large"
        startIcon={<Iconify icon="healthicons:money-bag"/>}
        onClick={() => setCurrentLocationHash('business')}
        sx={{
          borderBottom: currentLocationHash === 'business' ? '2px solid' : 'none',
          borderRadius: 0
        }}
      >
        Business
      </Button>
      <Button
        size="large"
        startIcon={<Iconify icon="solar:danger-bold"/>}
        onClick={() => setCurrentLocationHash('risk_factors')}
        sx={{
          borderBottom: currentLocationHash === 'risk_factors' ? '2px solid' : 'none',
          borderRadius: 0
        }}
      >
        Risks
      </Button>
      <Button
        size="large"
        startIcon={<Iconify icon="hugeicons:manager"/>}
        onClick={() => setCurrentLocationHash('mdna')}
        sx={{
          borderBottom: currentLocationHash === 'mdna' ? '2px solid' : 'none',
          borderRadius: 0
        }}
      >
        Management
      </Button>
      <Button
        size="large"
        startIcon={<Iconify icon="carbon:finance"/>}
        onClick={() => setCurrentLocationHash('financials')}
        sx={{
          borderBottom: currentLocationHash === 'financials' ? '2px solid' : 'none',
          borderRadius: 0
        }}
        >
        Financials
      </Button>
    </Stack>
  );

  const renderBusinessAnalysis = (
    <>
      {/* render summary */}
      {renderSummary("Overview: Item 1. Business", analysis.business_summary.questions.summary, analysis.business_summary.questions.concerns, analysis.business_summary.questions.strengths)}

      {/* @ts-ignore */}
      {analysis.business?.questions && analysis.business.questions.map((a, index) => (
        <Box sx={{mt: 3, fontSize: '0.9rem'}}>
           {/*
           <Divider sx={{mb: 2, borderBottomWidth: '3px', background:"black", width: "60%", textAlign:"center"}}/>
           */}
          <Divider sx={{mb: 2}}/>
          <Typography variant="h4">{index+1}. {a.question} {renderSources(a.sources, "business_")}</Typography>
          <Typography variant="body2" color="text.secondary">{a.subquestion}</Typography>

          <ShowMore initialExpanded={index === 0}>
            <ReactMarkdown>{a.answer}</ReactMarkdown>
          </ShowMore>
        </Box>
      ))}
    </>
  );

  const renderRisksAnalysis = (
    <>
      {/* render summary */}
      {renderSummary("Overview: Item 1A. Risk Factors", analysis.risk_factors_summary.questions.summary, analysis.risk_factors_summary.questions.concerns, analysis.risk_factors_summary.questions.strengths)}

      {/* @ts-ignore */}
      {analysis.risk_factors?.questions && analysis.risk_factors.questions.map((a, index) => (
        <Box sx={{mt: 3, fontSize: '0.9rem'}}>
          <Typography
            variant="h5">{a.question} {renderSources(a.sources, "risk_factors_")}</Typography>
          <Typography variant="body2" color="text.secondary">{a.subquestion}</Typography>

          <ShowMore initialExpanded={index === 0}>
            <ReactMarkdown>{a.answer}</ReactMarkdown>
          </ShowMore>
        </Box>
      ))}
    </>
  );

  const renderManagementAnalysis = (
    <>
      {/* render summary */}
      {renderSummary("Overview: Item 7. Management's Discussion", analysis.mdna_summary.questions.summary, analysis.mdna_summary.questions.concerns, analysis.mdna_summary.questions.strengths)}

      {/* @ts-ignore */}
      {analysis.mdna?.questions && analysis.mdna.questions.map((a, index) => (
        <Box sx={{mt: 3, fontSize: '0.9rem'}}>
          <Typography
            variant="h5">{a.question} {renderSources(a.sources, "mdna_")}</Typography>
          <Typography variant="body2" color="text.secondary">{a.subquestion}</Typography>

          <ShowMore initialExpanded={index === 0}>
            <ReactMarkdown>{a.answer}</ReactMarkdown>
          </ShowMore>


        </Box>
      ))}
    </>
  );

  const renderFinancialsAnalysis = (
    <>
      {/* render summary */}
      {renderSummary("Overview: Item 8: Financials", analysis.financials_summary.questions.summary, analysis.financials_summary.questions.concerns, analysis.financials_summary.questions.strengths)}

      {/* @ts-ignore */}
      {analysis.financials?.questions && analysis.financials.questions.map((a, index) => (
        <Box sx={{mt: 3, fontSize: '0.9rem'}}>
          <Typography
            variant="h5">{a.question} {renderSources(a.sources, "financials_")}</Typography>
          <Typography variant="body2" color="text.secondary">{a.subquestion}</Typography>

          <ShowMore initialExpanded={index === 0}>
            <ReactMarkdown>{a.answer}</ReactMarkdown>
          </ShowMore>
        </Box>
      ))}
    </>
  );

  const renderSearchButton = (search: ReturnType<typeof useBoolean>) => (
    <Button
      color="inherit"
      size="large"
      variant="contained"
      startIcon={<Iconify icon="eva:search-outline" width={24}/>}
      onClick={() => search.onTrue()}
      sx={{borderColor: 'text.primary'}}
    >
      Find Annual Report
    </Button>
  );

  const renderAnalysisWithTabs = (
    <>
      {/* search button */}
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Box sx={{mt: 1}}>
          <CustomSearchbar
            renderSearchButton={renderSearchButton}
            handleCompanyClickCustom={handleCompanyAddClick}
            country="us"
            tickersToHide={[]}/>
        </Box>
      </Stack>

      {/* company header */}
      {ticker && company?.ticker && renderCompanyHeader(company)}

      {/* report header */}
      {ticker && company?.ticker && renderReportHeader()}

      {/* report summary */}
      {/*
      {ticker && company?.ticker && analysis.summary.questions && renderSummary(analysis.summary.questions.summary, analysis.summary.questions.concerns, analysis.summary.questions.strengths)}
      */}


      {/* arrows */}
      {!isMobile && (
        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{width: '100%'}}>
          <Grid item xs={12} md={6}>
            <Stack direction="column" alignItems="center" justifyContent="center"
                   sx={{color: 'text.secondary'}}>
              <Typography variant="h6">AI Analysis</Typography>
              <Iconify icon="mingcute:arrow-down-fill" width={32} height={32}/>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="column" alignItems="center" justifyContent="center"
                   sx={{color: 'text.secondary'}}>
              <Typography variant="h6">Original Document</Typography>
              <Iconify icon="mingcute:arrow-down-fill" width={32} height={32}/>
            </Stack>
          </Grid>
        </Grid>
      )}

      {/* navigation */}
      <Box>
        {renderNavigationDesktop}
      </Box>

      {/* analysis */}
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{width: '100%'}}>
        <Grid item md={12} lg={6}>
          <Box
            sx={{
              overflowY: 'auto',
              ...customScrollbarStyles(theme),
              height: '90vh',
              width: '100%',
              mx: 2
            }}

          >
            {currentLocationHash === 'business' && renderBusinessAnalysis}
            {currentLocationHash === 'risk_factors' && renderRisksAnalysis}
            {currentLocationHash === 'mdna' && renderManagementAnalysis}
            {currentLocationHash === 'financials' && renderFinancialsAnalysis}
          </Box>
        </Grid>

        {!analysis.html && (
          <Typography variant="body1">No analysis available</Typography>
        )}

        {analysis.html && !isMobile && (
          <Grid item md={12} lg={6}>
            <Box
              sx={{
                overflowY: 'auto',
                ...customScrollbarStyles(theme),
                height: '90vh',
                width: '100%',
                mx: 2
              }}
            >

              <AnnualReportContent html={analysis.html} currentSourceId={currentSourceId}
                                   currentSection={currentLocationHash}/>
            </Box>
          </Grid>
        )}

        {analysis.html && isMobile && (
          <AnnualReportAnalysisDialog
            html={analysis.html}
            currentSourceId={currentSourceId}
            currentSection={currentLocationHash}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}/>
        )}

      </Grid>

    </>
  )

  const renderNoAnalysis = (
    <Stack direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{mt: 5}}>
      <Typography variant="h5">Please select annual report.</Typography>
      <CustomSearchbar
        renderSearchButton={renderSearchButton}
        handleCompanyClickCustom={handleCompanyAddClick}
        country="us"
        tickersToHide={[]}/>
    </Stack>
  );

  return (
    <Stack direction="column" spacing={2} alignItems="center" justifyContent="center"
           sx={{height: '100%', m: 2}}>
      {Object.keys(analysis?.business).length === 0 && renderNoAnalysis}

      {Object.keys(analysis?.business).length !== 0 && renderAnalysisWithTabs}

    </Stack>
  );
}
