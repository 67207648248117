import React, {useState} from 'react';
// @mui
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useAuthContext} from "../../auth/hooks";


export default function CompanyGrowthAiAnalysis({ticker, growthAnalysis}: {
  ticker: string | undefined,
  growthAnalysis: any
}) {
  const {authenticated} = useAuthContext();
  const [showFullGrowthHypothesis, setShowFullGrowthHypothesis] = useState(false);
  const [showFullDeclineHypothesis, setShowFullDeclineHypothesis] = useState(false);

  const toggleShowFullGrowthHypothesis = () => {
    setShowFullGrowthHypothesis(!showFullGrowthHypothesis);
  }
  const toggleShowFullDeclinethHypothesis = () => {
    setShowFullDeclineHypothesis(!showFullDeclineHypothesis);
  }

  if (!authenticated) {
    return (
      <Box/>
    );
  }
  if (!growthAnalysis.growth_hypothesis) {
    return (
      <Box sx={{mb: 3}}>
        <Typography variant="h6" sx={{color: 'text.primary'}}>
          No sufficient data to generate AI Analysis
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      <Typography variant="h6" sx={{color: 'text.primary'}}>
        Is {ticker} setup for long-term growth?
      </Typography>
      <Typography variant="body2">
        {growthAnalysis?.conclusion}
      </Typography>
      <Typography variant="h6" sx={{color: 'text.primary'}}>
        Growth Hypothesis
      </Typography>

      {/* Growth Hypothesis */}
      <Typography variant="body2">
        <div style={{
          maxHeight: showFullGrowthHypothesis ? 'none' : '3em',
          overflow: showFullGrowthHypothesis ? 'visible' : 'hidden'
        }}>
          {growthAnalysis?.growth_hypothesis}
        </div>
      </Typography>
      <Button variant="text" color="primary" onClick={toggleShowFullGrowthHypothesis}
              sx={{fontSize: 12}}>
        {showFullGrowthHypothesis ? "Show Less" : "Show More"}
      </Button>

      {/* Decline Hypothesis */}
      <Typography variant="h6" sx={{color: 'text.primary'}}>
        Decline Hypothesis
      </Typography>
      <Typography variant="body2">
        <div style={{
          maxHeight: showFullDeclineHypothesis ? 'none' : '3em',
          overflow: showFullDeclineHypothesis ? 'visible' : 'hidden'
        }}>
          {growthAnalysis?.decline_hypothesis}
        </div>
      </Typography>
      <Button variant="text" color="primary" onClick={toggleShowFullDeclinethHypothesis}
              sx={{fontSize: 12}}>
        {showFullDeclineHypothesis ? "Show Less" : "Show More"}
      </Button>

      {/*  Predictions assessment */}
      <Typography variant="h6" sx={{color: 'text.primary'}}>
        Analysts Predictions
      </Typography>
      <Typography variant="body2">
        {growthAnalysis?.growth_predictions_assessment}
      </Typography>


    </Box>
  );
}
