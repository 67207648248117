import React from "react";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {fDateTime} from "../../utils/format-time";

export interface NewsSingleType {
  id: number,
  title: string,
  image: string,
  site: string,
  text: string,
  url: string,
  publishedDate: string,
}

export interface NewsAnalysisType {
  themes: {
    "theme_title": string,
    "theme_analysis": string,
    "news_ids": number[],
    "label": "good" | "bad" | "neutral",
  }[];
  news: NewsSingleType[];
}


export default function NewsNews({
                                   id,
                                   title,
                                   image,
                                   site,
                                   text,
                                   url,
                                   publishedDate
                                 }: NewsSingleType) {
  const hoverStyles = {
    transition: 'transform 0.3s ease-in-out',
    // make news title color primary on hover
    "&:hover": {
      cursor: "pointer",
      transform: 'scale(1.01)' // Slightly enlarge on hover
    },
    '&:hover .news-title': {
      color: 'primary.main'
    }
  }

  return (
    <Box sx={{mx: 2, mb: 1, ...hoverStyles}}  key={id} onClick={() => window.open(url, "_blank")}>
      <Stack direction="row" spacing={1}>
        <Box
          component="img"
          sx={{
            maxHeight: 67.5,
            maxWidth: 120,
            borderRadius: 1,
          }}
          alt="News image"
          src={image}
        />
        {/*
        open news in new tab when clicking on the box
        */}
        <Box>
          <Typography className="news-title" variant="body2" >{title}</Typography>

          {/* publishedDate is in format 2024-07-08 09:02:10, make it to show as 1 hours ago, or 1 day ago */}
          <Typography variant="caption"
                      sx={{color: "text.secondary"}}>{site} | {fDateTime(publishedDate)}</Typography>

        </Box>
      </Stack>
    </Box>

  )
}
