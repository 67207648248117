import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { customScrollbarStyles } from "../../theme/css";
import { features } from "./constants";

const featuresWithId = features.map((feature, index) => ({...feature, id: index}));

export default function FeaturesPreviewMobile() {
  const theme = useTheme();

  const renderFeatureCards = (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        width: '100%',
        overflowX: 'auto', // Enable horizontal scrolling
        ...customScrollbarStyles,
      }}
    >
      {featuresWithId.map((feature, index) => (
        <Box
          key={feature.id}
          sx={{
            display: 'flex',
            width: '80vw', // Each feature element takes 90% of the viewport width
            minWidth: '80vw',
            marginRight: index !== featuresWithId.length - 1 ? '2vw' : 0, // Small part of the next element visible
            '&:hover': {
              bgcolor: 'action.hover',
              cursor: 'pointer',
            },
          }}
        >
          <Stack direction="column" spacing={1} sx={{ width: '100%' }} className="feature-element">
            <Typography variant="body2" fontWeight={700}>{feature.title}</Typography>
            <Box
              component="img"
              alt={featuresWithId[index].title}
              src={featuresWithId[index].image}
              sx={{
                width: '100%',
                height: 'auto',
                maxHeight: 'calc(100% - 48px)', // Adjust for the toolbar height
                aspectRatio: '16/9',
              }}
            />
            <Typography variant="body2" sx={{ mt: 1, fontSize: 13 }}>
              {feature.description}
            </Typography>
          </Stack>
        </Box>
      ))}
    </Stack>
  );

  return (
    <>
      {renderFeatureCards}
    </>
  );
}
