import { ApexOptions } from 'apexcharts';
// @mui
import CardHeader from '@mui/material/CardHeader';
import Card, { CardProps } from '@mui/material/Card';
// components
import ScoreRadarStyled from "./score-radar-styled";

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chart: {
    categories: (string | string[])[];
    colors?: string[];
    series: {
      name: string;
      data: number[];
    }[];
    options?: ApexOptions;
  };
}

export default function ScoreRadar({ title, subheader, chart, ...other }: Props) {
  return (
    <Card {...other} sx={{height: '100%', width: '100%'}}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 1 }} />
      <ScoreRadarStyled chart={chart} />
    </Card>
  );
}
