import ReactGA from "react-ga4";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

// send ga timing event with action, label and value
export function gaTimingEvent(section: string, timeInSec: number) {
      // console.log(`Time spent on ${section}: ${timeInSec} seconds`);
      ReactGA.event({
        category: 'Feature Timing',
        action: `Time spent on ${section}`,
        value: timeInSec, // GA4 prefers values in seconds for timing
        label: section,
      });
}

// track visited pages
export function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: document.title,
    });
  }, [location]);
}


// ===================================================
// Purchase Journey
// ===================================================
// view product
export function gaViewProduct() {
    ReactGA.event({
      category: 'Ecommerce',
      action: 'view_item',
      label: 'View Product',
    });
}

// add to cart
export function gaAddToCart() {
    ReactGA.event({
      category: 'Ecommerce',
      action: 'add_to_cart',
      label: 'Add to Cart',
    });
}

// begin checkout
export function gaBeginCheckout() {
    ReactGA.event({
      category: 'Ecommerce',
      action: 'begin_checkout',
      label: 'Begin Checkout',
    });
}

// purchase
export function gaPurchase() {
    ReactGA.event({
      category: 'Ecommerce',
      action: 'purchase',
      label: 'Purchase',
    });
}
