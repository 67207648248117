import {ApexOptions} from "apexcharts";
import React from "react";
import {useTheme} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import numeral from "numeral";

import Chart from "../chart/chart";
import {useChart} from "../chart";


export interface RevenueBreakdownType {
  product_breakdown: {
    date: string,
    breakdown: {
      [key: string]: number
    }
  },
  geo_breakdown: {
    date: string,
    breakdown: {
      [key: string]: number
    }
  },
}



export default function CompanyBusinessRevenueBreakdown({
                                                          product_breakdown,
                                                          geo_breakdown
                                                        }: RevenueBreakdownType) {
  const theme = useTheme();

  const isXsOrMd = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const chartHeight = isXsOrMd ? 500 : 300;
  const getChartOptions = (chartTitle: string, chartLabels: string[], chartCategories: string[], totalRevenue: number) => ({
    chart: {
      width: '100%',
      type: 'donut',
    },
    stroke: {
      colors: [theme.palette.background.paper],
    },
    title: {
      text: chartTitle,
    },
    xaxis: {
      categories: chartCategories,
    },
    labels: chartLabels,
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter(val: number) {
          return numeral(val).format('0.00a');
        }
      }
    },
    legend: {
      position: 'right',
      offsetY: 10,
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '14px',
            },
            value: {
              show: true,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: undefined,
              formatter(w: number) {
                return numeral(w).format('0.00a');
              }
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              fontSize: '14px',
              formatter(w: number) {
                return numeral(totalRevenue).format('0.00a');
              }
            }
          }
        }
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          height: 100,
        }
      }
    }],
  })
  let productChartOptions = {};
  try {
    if (product_breakdown && product_breakdown.breakdown) {
      const productLabels = Object.keys(product_breakdown.breakdown);
      const productCategories = Object.keys(product_breakdown.breakdown)
      const totalProductRevenue = Object.values(product_breakdown.breakdown).reduce((a, b) => a + b, 0);
      productChartOptions = getChartOptions(
        `Revenue by Product (${product_breakdown.date})`,
        productLabels,
        productCategories,
        totalProductRevenue
      ) as unknown as ApexOptions;
    }
  } catch (error) {
    console.log("Error generating product chart options:", error);
  }

  productChartOptions = useChart(productChartOptions);

  let geoChartOptions = {};
  try {
    if (geo_breakdown && geo_breakdown.breakdown) {
      const geoLabels = Object.keys(geo_breakdown.breakdown);
      const geoCategories = Object.keys(geo_breakdown.breakdown)
      const totalGeoRevenue = Object.values(geo_breakdown.breakdown).reduce((a, b) => a + b, 0);
      geoChartOptions = getChartOptions(
        `Revenue by Geography (${geo_breakdown.date})`,
        geoLabels,
        geoCategories,
        totalGeoRevenue
      ) as unknown as ApexOptions;
    }
  } catch (error) {
    console.log("Error generating geo chart options:", error);
  }

  geoChartOptions = useChart(geoChartOptions);


  return (
    <Grid container alignItems="center" justifyContent="center">
      {product_breakdown && product_breakdown.breakdown ? (
        <Grid item xs={12} xl={6}>
          <Stack direction="column" sx={{mt: 1}}>
            <Chart dir="ltr" type="donut"
                   series={Object.values(product_breakdown.breakdown)}
                   options={productChartOptions} height={chartHeight}/>
          </Stack>
        </Grid>
      ) : (
        <Box/>
      )
      }

      {geo_breakdown && geo_breakdown.breakdown ? (
        <Grid item xs={12} xl={6}>
          <Stack direction="column" sx={{mt: 1}}>
            <Chart dir="ltr" type="donut"
                   series={Object.values(geo_breakdown.breakdown)}
                   options={geoChartOptions} height={chartHeight}/>
          </Stack>
        </Grid>
      ) : (
        <Box/>
      )
      }
    </Grid>
  );
}
