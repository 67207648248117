import React from "react";
import Typography from "@mui/material/Typography";
import moment from "moment-timezone";

export default function GeneratedOn({ dateToDisplay }: { dateToDisplay: string }) {
  // Get the user's local timezone
  const userTimezone = moment.tz.guess();

  if (!dateToDisplay) {
    return null;
  }

  // Convert the UTC date to the user's local timezone
  const formattedDate = moment.utc(dateToDisplay).tz(userTimezone).format('D MMM YYYY, h:mm A z');

  return (
    <Typography variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
      Generated on <strong>{formattedDate}</strong>
    </Typography>
  );
}
