import React, {createContext, useState, useContext, useEffect, useMemo, useCallback} from 'react';
import axios, {endpoints} from "../utils/axios";
import {AuthContext} from "../auth/context/jwt";

export interface UserCredits {
  credits: number;
  next_renewal: string;
}

const CreditsContext = createContext({userCredits: {credits: 0, next_renewal: ""}, refreshCredits: () => {}});

export const useCredits = () => useContext(CreditsContext);

type Props = {
  children: React.ReactNode;
};

export const CreditsProvider = ({children}: Props) => {
  const {authenticated} = useContext(AuthContext);
  const [userCredits, setCredits] = useState<UserCredits>({credits: 0, next_renewal: ""});

  const refreshCredits = useCallback(async () => {
    const result = await axios.get<UserCredits>(endpoints.user_credits, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const {credits, next_renewal} = result.data;
    setCredits({credits, next_renewal});
  }, []);

  useEffect(() => {
    if (authenticated) {
      refreshCredits();
    }
  }, [authenticated, refreshCredits]);

  const value = useMemo(() => ({userCredits, refreshCredits}), [userCredits, refreshCredits]);
  return (
    <CreditsContext.Provider value={value}>
      {children}
    </CreditsContext.Provider>
  );
};
