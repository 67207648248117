import React from "react";
import {Box, Collapse, Grid, Stack, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";

import {customScrollbarStyles} from "../../theme/css";
import {features} from "./constants";

// add integer id to each feature and get a new array
const featuresWithId = features.map((feature, index) => ({...feature, id: index}));

export default function FeaturesPreviewDesktop() {
  const [selectedFeature, setSelectedFeature] = React.useState(0);
  const theme = useTheme();

  const renderSidebar = (
    <Box sx={{width: '100%', height: '100%', overflowY: 'auto', ...customScrollbarStyles(theme)}}>
      <Box>
        {featuresWithId.map((feature) => (
          <Box
            key={feature.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              px: 2,
              py: 1,
              '&:hover': {
                bgcolor: 'action.hover',
                cursor: 'pointer',
              },
              ...(selectedFeature === feature.id && {
                // bgcolor: 'action.selected',
              }),
            }}
            onClick={() => setSelectedFeature(feature.id)}
          >
            <Stack direction="column" spacing={1}>
              <Typography variant="body2" fontWeight={700}>{feature.title}</Typography>

              <Collapse in={selectedFeature === feature.id}>
                <Typography variant="body2" sx={{mt: 1, fontSize: 13}}>
                  {feature.description}
                </Typography>
              </Collapse>


            </Stack>
          </Box>
        ))}
      </Box>
    </Box>
  );

  const renderSelectedFeature = (
    <Stack
      direction="column"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      {/* Toolbar */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          bgcolor: 'action.selected',
          // borderBottom: '1px solid',
          borderColor: 'divider',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          p: 1.5,
        }}
      >
        <Box
          sx={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            backgroundColor: "#ff5f57",
            mr: 1
          }}
        />
        <Box
          sx={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            backgroundColor: "#ffbd2e",
            mr: 1
          }}
        />
        <Box
          sx={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            backgroundColor: "#28c940",
          }}
        />
      </Box>

      {/* Image */}
      <Box
        component="img"
        alt={featuresWithId[selectedFeature].title}
        src={featuresWithId[selectedFeature].image}
        sx={{
          width: '100%',
          height: 'auto',
          maxHeight: 'calc(100% - 48px)', // Adjust for the toolbar height
          aspectRatio: '16/9',
          borderBottom: '1px solid',
          borderLeft: '1px solid',
          borderRight: '1px solid',
          borderColor: 'divider',
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        }}
      />
    </Stack>
  );

  return (
    <Grid container sx={{height: 600}} spacing={2}>
      <Grid item xs={3} sx={{height: '100%'}}>
        {renderSidebar}
      </Grid>
      <Grid item xs={9} sx={{display: 'flex'}}>
        {renderSelectedFeature}
      </Grid>
    </Grid>
  );
}
