import React, {useState} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import Popover from "@mui/material/Popover";

interface QuickRatioProps {
  valuation: {
    key: string;
    value: number;
    formatter: (value: number) => string;
    helpText: string;
  }[];
  solvency: {
    key: string;
    value: number;
    formatter: (value: number) => string;
    helpText: string;
  }[];
  efficiency: {
    key: string;
    value: number;
    formatter: (value: number) => string;
    helpText: string;
  }[];
  growth: {
    key: string;
    value: number;
    formatter: (value: number) => string;
    helpText: string;
  }[];
}

const hoverStyles = {
  transition: 'transform 0.3s ease-in-out',
  "&:hover": {
    cursor: "help",
    transform: 'scale(1.03)' // Slightly enlarge on hover
  },
}

export default function QuickRatios({valuation, solvency, efficiency, growth}: QuickRatioProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event: any, popContent: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(popContent);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent('');
  };
  return <Box sx={{width: "100%"}}>
    <Grid container spacing={3}>
      <Grid item xs={12} lg={3} xl={3}>
        <Stack direction="column" spacing={0.5}>
          <Divider textAlign="left"><strong>Valuation</strong></Divider>
          {valuation.map(pd => (
            <Stack direction="row"
                   justifyContent="space-between"
                   key={pd.key}
                   onMouseEnter={(e) => handlePopoverOpen(e, pd.helpText)}
                   onMouseLeave={handlePopoverClose}
                   sx={{
                     ...hoverStyles,
                   }}
            >
              <Typography variant="body2" sx={{color: 'text.secondary'}}>{pd.key}</Typography>
              <Typography variant="body2">{pd.formatter(pd.value) || "-"}</Typography>
            </Stack>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12} lg={3} xl={3}>
        <Stack direction="column" spacing={0.5}>
          <Divider textAlign="left"><strong>Solvency</strong></Divider>
          {solvency.map(pd => (
            <Stack direction="row"
                   justifyContent="space-between"
                   key={pd.key}
                   onMouseEnter={(e) => handlePopoverOpen(e, pd.helpText)}
                   onMouseLeave={handlePopoverClose}
                   sx={{
                     ...hoverStyles,
                   }}
            >
              <Typography variant="body2" sx={{color: 'text.secondary'}}>{pd.key}</Typography>
              <Typography variant="body2">{pd.formatter(pd.value) || "-"}</Typography>
            </Stack>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12} lg={3} xl={3}>
        <Stack direction="column" spacing={0.5}>
          <Divider textAlign="left"><strong>Efficiency</strong></Divider>
          {efficiency.map(pd => (
            <Stack direction="row"
                   justifyContent="space-between"
                   key={pd.key}
                   onMouseEnter={(e) => handlePopoverOpen(e, pd.helpText)}
                   onMouseLeave={handlePopoverClose}
                   sx={{
                     ...hoverStyles,
                   }}
            >
              <Typography variant="body2" sx={{color: 'text.secondary'}}>{pd.key}</Typography>
              <Typography variant="body2">{pd.formatter(pd.value) || "-"}</Typography>
            </Stack>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12} lg={3} xl={3}>
        <Stack direction="column" spacing={0.5}>
          <Divider textAlign="left"><strong>Growth</strong></Divider>
          {growth.map(pd => (
            <Stack direction="row"
                   justifyContent="space-between"
                   key={pd.key}
                   onMouseEnter={(e) => handlePopoverOpen(e, pd.helpText)}
                   onMouseLeave={handlePopoverClose}
                   sx={{
                     ...hoverStyles,
                   }}
            >
              <Typography variant="body2" sx={{color: 'text.secondary'}}>{pd.key}</Typography>
              <Typography variant="body2">{pd.formatter(pd.value) || "-"}</Typography>
            </Stack>
          ))}
        </Stack>
      </Grid>
    </Grid>
    <Popover
      id="mouse-over-popover"
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
      onClose={handlePopoverClose}
      disableRestoreFocus
      disableScrollLock
      sx={{pointerEvents: 'none'}}
      slotProps={{
        paper: {style: {width: 250}},
      }}
    >
      <Typography variant="body2" sx={{p: 0.5, fontSize: 12}}>
        {popoverContent}
      </Typography>
    </Popover>
  </Box>;
}
