import React, {useCallback, useEffect, useState} from 'react';

// @mui
import {useTheme} from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import Typography from "@mui/material/Typography";
import Chart, {useChart} from 'src/components/chart';
import ButtonBase from "@mui/material/ButtonBase";
import MenuItem from "@mui/material/MenuItem";
import {Box} from "@mui/material";

import numeral from "numeral";

import Iconify from "../iconify";
import CustomPopover, {usePopover} from "../custom-popover";


interface EstimatedGrowth {
  date: string;
  low: number;
  high: number;
  avg: number;
}

export interface EstimatedGrowthData {
  revenueChange: number;
  ebitdaChange: number;
  epsChange: number;
  estimatedRevenueGrowth: EstimatedGrowth[];
  estimatedEbitdaGrowth: EstimatedGrowth[];
  estimatedEpsGrowth: EstimatedGrowth[];
}

export default function CompanyGrowthChart({
                                             revenueChange,
                                             ebitdaChange,
                                             epsChange,
                                             estimatedRevenueGrowth,
                                             estimatedEbitdaGrowth,
                                             estimatedEpsGrowth
                                           }: EstimatedGrowthData) {
  const theme = useTheme();
  const successLight = theme.palette.primary.light;

  const popover = usePopover();
  const [annotationX, setAnnotationX] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [currentSeriesKey, setCurrentSeriesKey] = useState('Predicted Revenue');


  const revenueValues: number[] = estimatedRevenueGrowth.map(data => data.avg);
  const ebitdaValues: number[] = estimatedEbitdaGrowth.map(data => data.avg);
  const epsValues: number[] = estimatedEpsGrowth.map(data => data.avg);

  const seriesKeyToChange: { [key: string]: number } = {
    "Predicted Revenue": revenueChange,
    "Predicted EBITDA": ebitdaChange,
    "Predicted EPS": epsChange,
  }

  const seriesKeyToValues: { [key: string]: number[] } = {
    "Predicted Revenue": revenueValues,
    "Predicted EBITDA": ebitdaValues,
    "Predicted EPS": epsValues,
  }
  const categoriesValues = estimatedRevenueGrowth.map(data => data.date);

  const handleChangeSeries = useCallback((newValue: string) => {
    popover.onClose();
    setCurrentSeriesKey(newValue);

    const seriesKeyToData: { [key: string]: EstimatedGrowth[] } = {
      "Predicted Revenue": estimatedRevenueGrowth,
      "Predicted EBITDA": estimatedEbitdaGrowth,
      "Predicted EPS": estimatedEpsGrowth,
    }
    const latestData = seriesKeyToData[newValue][seriesKeyToData[newValue].length - 2];
    setAnnotationX(latestData.date);
    setLastDate(seriesKeyToData[newValue][0].date);
  }, [popover, setCurrentSeriesKey, estimatedEbitdaGrowth, estimatedEpsGrowth, estimatedRevenueGrowth]);

  useEffect(() => {
    setTimeout(() => {
      handleChangeSeries('Predicted Revenue');
    }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionsLine = useChart({
    series: [
      {
        name: currentSeriesKey,
        data: seriesKeyToValues[currentSeriesKey],
      },
    ],
    chart: {
      id: 'chart2',
      type: 'line',
      height: 230,
      toolbar: {
        autoSelected: 'pan',
        show: false
      }
    },
    tooltip: {
      fillSeriesColor: false,
      x: {
        formatter(val: number) {
          // Convert the timestamp to a Date object
          const date = new Date(val);
          // Format the date
          return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          });
        }
      },
      y: {
        formatter(val: number) {
          return numeral(val).format('0.00a');
        }
      }
    },
    colors: [successLight],
    stroke: {
      width: 3
    },
    dataLabels: {
      enabled: false,
      // format labels for big numbers
      formatter(val: number) {
        return numeral(val).format('0.00a');
      }
    },
    fill: {
      opacity: 1,
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: 'datetime',
      categories: categoriesValues,
      tooltip: {
        enabled: true,
        formatter(val: string, opts?: object) {
          // Convert the timestamp to a Date object
          const date = new Date(val);
          // Format the date
          return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          });
        }
      }
    },
    yaxis: {
      labels: {
        formatter(val: number) {
          return numeral(val).format('0.00a');
        }
      }
    },
    forecastDataPoints: {
      count: 1
    },
    annotations: {
      xaxis: [
        {
          x: new Date(annotationX).getTime(),
          x2: new Date(lastDate).getTime(),
          fillColor: '#B3F7CA',
          label: {
            offsetX: 26,
            offsetY: 18,
            style: {
              fontSize: '13px',
            },
            orientation: "horizontal",
            text: 'Past Values <> Analysts Predictions',
          }
        }
      ],
    }
  });

  // @ts-ignore
  if (!estimatedRevenueGrowth.length && !estimatedEbitdaGrowth.length && !estimatedEpsGrowth.length) {
    return (
      <Box sx={{mb: 3}}>
        <Typography variant="h6" sx={{color: 'text.primary'}}>
          Growth Analysis
        </Typography>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          Growth data is not available
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Card>
        <CardHeader
          title="Growth Forecasts"
          subheader={
            <Typography
              variant="body2"
              component="span"
              color="text.secondary"
            >
              {/*
              {currentSeriesKey}: <b>{numeral(seriesKeyToValues[currentSeriesKey][0]).format('0.00a')}</b>
              */}
              <b>{currentSeriesKey.split(" ")[1]}</b> is forecasted
              to {seriesKeyToChange[currentSeriesKey] >= 0 ? "grow" : "decline"} by <b>{seriesKeyToChange[currentSeriesKey]}%</b> per year for
              the given period.
            </Typography>
          }
          action={
            <ButtonBase
              onClick={popover.onOpen}
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                bgcolor: 'background.neutral',
              }}
            >
              {currentSeriesKey}

              <Iconify
                width={16}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ml: 0.5}}
              />
            </ButtonBase>
          }
        />
        <Chart type="line"
               dir="ltr"
               series={optionsLine.series}
               options={optionsLine}
               height={394}
        />

      </Card>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{width: 140}}>
        {Object.keys(seriesKeyToValues).map((key) => (
          <MenuItem
            key={key}
            selected={key === currentSeriesKey}
            onClick={() => handleChangeSeries(key)}
          >
            {key}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
