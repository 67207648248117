import React, {useEffect, useState} from "react";
import {Box, Collapse, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import axios, {endpoints} from "../../utils/axios";
import AiProgressbar from "../utility_components/ai-progressbar";
import Iconify from "../iconify";
import Label from "../label";
import {NewsAnalysisType} from "../company/news_news";
import NewsContainer from "../company/news_container";


interface GeneralNewsAnalysisProps {
  newsAnalysis: NewsAnalysisType;
  maxHeight?: number | string;
}


export default function GeneralNewsAnalysis({newsAnalysis, maxHeight = "700px"}: GeneralNewsAnalysisProps) {
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);
  const [themeIdToShowNews, setThemeIdToShowNews] = useState<boolean[]>([]);

  const hoverStyles = {
    transition: 'transform 0.3s ease-in-out',
    "&:hover": {
      cursor: "pointer",
      transform: 'scale(1.01)' // Slightly enlarge on hover
    },
    '&:hover .theme-title': {
      color: 'primary.main'
    }
  }


  const theme = useTheme();
  const customScrollbarStyles = {
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '8px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.grey[600]
    },
  };

  const getLabelElement = (sentiment: string) => {
    switch (sentiment) {
      case "bullish":
        return <Label color="success"> Bullish </Label>
      case "bearish":
        return <Label color="error"> Bearish </ Label>
      case "neutral":
        return <Label color="info"> Neutral </ Label>
      default:
        return <Label color="info"> Neutral </ Label>
    }
  }

  if (!newsAnalysis || isLoadingAnalysis) {
    return <AiProgressbar/>
  }

  return (
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={12} xl={12} sx={{mb: 1}}>
          <Box
            sx={{
              maxHeight,
              overflowY: 'auto', // Enable vertical scrolling
              ...customScrollbarStyles
            }}
          >
            {newsAnalysis.themes.map((t, index) => (
              <Box key={t.theme_title} sx={{mt: 2, mx: 2}}>
                <Stack direction="row" spacing={1} onClick={() => {
                  setThemeIdToShowNews(prevState => {
                    const newState = [...prevState];
                    newState[index] = !newState[index];
                    return newState;
                  });
                }}
                       sx={{mt: 1, ...hoverStyles}}
                >
                  <Box>
                    {getLabelElement(t.label)}
                  </Box>
                  <Typography className="theme-title" variant="h6">{t.theme_title}</Typography>
                  <Box>
                    {themeIdToShowNews[index] ? <Iconify icon="eva:chevron-up-fill" width={24}/> :
                      <Iconify icon="eva:chevron-down-fill" width={24}/>}
                  </Box>
                </Stack>
                <Typography variant="caption" sx={{mt: 2}} color="text.secondary">Summary:</Typography>
                <Typography variant="body2" sx={{mb: 1}}>{t.theme_analysis}</Typography>
                <Button variant="text" color="primary"
                        onClick={() => {
                          setThemeIdToShowNews(prevState => {
                            const newState = [...prevState];
                            newState[index] = !newState[index];
                            return newState;
                          });
                        }}
                        sx={{fontSize: 11, ml: 1}}
                >
                  {themeIdToShowNews[index] ? "Hide News" : "Show News"}
                </Button>
                <Collapse in={themeIdToShowNews[index]}>
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <NewsContainer
                    news={newsAnalysis.news.filter((n) => newsAnalysis.themes[index].news_ids.includes(n.id))}/>
                </Collapse>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
  )
}
