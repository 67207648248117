import React, { useState, useCallback } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

// Define a type for the error object
type ErrorObj = {
  message: string;
};

let showErrorOutside: (error: ErrorObj) => void = () => {};

const ErrorAlert: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const showError = useCallback((error: ErrorObj) => {
    setErrorMessage(error.message);
    setOpen(true);
  }, []);

  showErrorOutside = showError;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

const showError = (error: ErrorObj) => {
  showErrorOutside(error);
};

export { ErrorAlert, showError };
