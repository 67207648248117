import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";


export default function SurveyQuestionFreeForm({text, response, setResponse}: {
  text: string, response: string; setResponse: (response: any) => void
}) {
  const renderContent = (
    <Box sx={{width: '90%'}}>
      <Typography variant="h6" gutterBottom>{text}</Typography>
      <TextField
        variant="outlined"
        fullWidth
        multiline
        rows={6}
        placeholder="Type your answer here..."
        value={response || ''}
        onChange={(e) => setResponse(e.target.value)}
      />
    </Box>
  );
  return (
    <>
      {renderContent}
    </>
  );
}
