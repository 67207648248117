import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function SurveyEntry() {
  const renderContent = (
    <Box>
      <Typography variant="h6" textAlign="center" gutterBottom>Your feedback is important for us!</Typography>
      <Typography variant="body1" textAlign="center" gutterBottom>Please do this short survey and earn <strong>5 free </strong>credits! 🚀</Typography>
    </Box>
  );
  return (
    <>
      {renderContent}
    </>
  );
}
