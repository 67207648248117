export function addToVisitedCompanies(ticker: string) {
  localStorage.setItem('visitedCompanies', JSON.stringify([...getVisitedCompanies(), ticker]));
}

export function getVisitedCompanies() {
  return JSON.parse(localStorage.getItem('visitedCompanies') || '[]');
}

export function setVisitedCompanies(companies: string[]) {
  localStorage.setItem('visitedCompanies', JSON.stringify(companies));
}

export function setVisitedCompaniesToTwo() {
  const companies = getVisitedCompanies();
  setVisitedCompanies(companies.slice(0, 2));
}
