import LinearProgress from "@mui/material/LinearProgress";
import {alpha} from "@mui/material/styles";
import React from "react";
import Box from "@mui/material/Box";

export default function ScoreBreakdownProgressElement({
                                                        score,
                                                        widthParam,
                                                        heightParam = "8px",
                                                        isVertical = false,
                                                        sx,
                                                      }: {
  score: number;
  widthParam?: number | string;
  heightParam?: number | string;
  isVertical?: boolean;
  // accept sx as a prop
  sx?: any;
}) {
  // Define a function to determine the color based on the score
  const getColor = (value: number) => {
    // Map the score to a color gradient from red to light green
    const red = Math.max(255 - (value * 2.55), 0); // Closer to 0, more red
    const green = Math.min(value * 2.55, 200); // Closer to 100, lighter green (adjust to your preference)
    // Convert RGB values to a CSS color string
    return `rgb(${red.toFixed(0)}, ${green.toFixed(0)}, 0)`;
  };

  // Calculate the color based on the score
  const progressColor = getColor(score * 20);
  if (!isVertical) {
    return (
      <LinearProgress
        variant="determinate"
        value={score * 20}
        sx={{
          width: widthParam, // Use the width prop if provided
          height: heightParam, // Use the height prop
          bgcolor: (theme) => alpha(theme.palette.success.main, 0.16),
          // Set the color dynamically based on the score
          "& .MuiLinearProgress-bar": {
            backgroundColor: progressColor,
          },
          ...sx, // Spread the sx prop to override styles
        }}
      />
    );
  }
  return (
    <Box
      sx={{
        height: heightParam,
        width: widthParam,
        backgroundColor: "background.neutral",
        position: 'relative',
        borderRadius: '4px',
        overflow: 'hidden',
        ...sx, // Spread the sx prop to override styles
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: `${score * 20}%`,
          backgroundColor: progressColor,
          position: 'absolute',
          borderRadius: '4px',
          bottom: 0,
        }}
      />
    </Box>
  )
}
