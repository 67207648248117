import React, {useEffect} from "react";
import {Box, useMediaQuery} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import GeneralNewsAnalysis from "./general-news-analysis";
import Iconify from "../iconify";
import axios, {endpoints} from "../../utils/axios";
import {useAuthContext} from "../../auth/hooks";
import { LoginToAccess } from "../utility_components/login-to-access";
import GeneratedOn from "../utility_components/generated-on";
import {gaTimingEvent} from "../../utils/ga";


export default function GeneralNewsAllTabs() {
  const [value, setValue] = React.useState('1');
  const [newsAnalyses, setNewsAnalyses] = React.useState({});
  const [dateGenerated, setDateGenerated] = React.useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const {authenticated} = useAuthContext();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // track time spent
  useEffect(() => {
    const startTime = Date.now(); // Record start time

    return () => {
      const endTime = Date.now(); // Record end time
      const timeSpentMs = endTime - startTime; // Calculate time spent in milliseconds
      const timeSpentSec = Math.round(timeSpentMs / 1000); // Convert to seconds

      gaTimingEvent("Markets Overview", timeSpentSec)
    };
  }, []);


  // fetch all news analyses
  useEffect(() => {
    if (authenticated) {
      const markets = ['us', 'eu', 'uk', 'china'];
      markets.forEach(market => {
        axios.get<any>(`${endpoints.ai_analysis_news}?market=${market}`)
          .then(analysisResponse => {
            const parsedAnalysis = JSON.parse(analysisResponse.data.answer);
            parsedAnalysis.themes = parsedAnalysis.themes.sort((a: any, b: any) => a.label.localeCompare(b.label));
            setNewsAnalyses(prevState => ({...prevState, [market]: parsedAnalysis}));
            setDateGenerated(analysisResponse.data.datetime_generated);
          })
          .catch(err => {
            console.error(err);
          })
      })
    }

  }, [authenticated]);

  const renderTabs = () => (

    <Box sx={{width: '100%', typography: 'body1', mt: 3}}>
      <TabContext value={value}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
          <TabList onChange={handleChange}>
            <Tab
              icon={<Iconify icon="flagpack:us" sx={{borderRadius: 0.65, width: 28}}/>}
              label="US"
              value="1"
            />
            <Tab
              icon={<Iconify icon="flag:eu-4x3" sx={{borderRadius: 0.65, width: 28}}/>}
              label="EU"
              value="2"
            />
            <Tab
              icon={<Iconify icon="flagpack:gb-ukm" sx={{borderRadius: 0.65, width: 28}}/>}
              label="UK"
              value="3"
            />
            <Tab
              icon={<Iconify icon="flagpack:cn" sx={{borderRadius: 0.65, width: 28}}/>}
              label="China"
              value="4"
            />

          </TabList>
        </Box>
        <Box sx={{ml: 3}}>
          <GeneratedOn dateToDisplay={dateGenerated}/>
        </Box>
        <TabPanel value="1" sx={{p: 1}}>
          {/* @ts-ignore */}
          <GeneralNewsAnalysis newsAnalysis={newsAnalyses.us}
                               maxHeight={isMobile ? "100%" : "700px"}/>
        </TabPanel>
        <TabPanel value="2" sx={{p: 1}}>
          {/* @ts-ignore */}
          <GeneralNewsAnalysis newsAnalysis={newsAnalyses.eu}
                               maxHeight={isMobile ? "100%" : "700px"}/>
        </TabPanel>
        <TabPanel value="3" sx={{p: 1}}>
          {/* @ts-ignore */}
          <GeneralNewsAnalysis newsAnalysis={newsAnalyses.uk}
                               maxHeight={isMobile ? "100%" : "700px"}/>
        </TabPanel>
        <TabPanel value="4" sx={{p: 1}}>
          {/* @ts-ignore */}
          <GeneralNewsAnalysis newsAnalysis={newsAnalyses.china}
                               maxHeight={isMobile ? "100%" : "700px"}/>
        </TabPanel>
      </TabContext>
    </Box>
  );

  return (
    <>
      <Typography variant="h5" sx={{mt: 2}}>Latest News Summary</Typography>
      <Typography variant="body2" sx={{color: "text.secondary"}}>Summary of the latest
        news grouped by economy and topic. Click on <b>{`Show News  `}</b> to see the actual articles
        for each topic.</Typography>

      <LoginToAccess returnTo="/dashboard#markets-overview" featureDescription="markets overview" >
        {renderTabs()}
      </LoginToAccess>
    </>

  );
}
