import React, {useEffect, useState} from "react";
import {Box, Grid, LinearProgress} from "@mui/material";
import Stack from "@mui/material/Stack";

import CompanyValuationHistoricalChart, {HistoricalData} from "./company-valuation-historical-chart";
import CompanyValuationPeersComparison, {PeersData} from "./company-valuation-peers-comparison";
import CompanyValuationAiAnalysis from "./company-valuation-ai-analysis";
import axios, {endpoints} from "../../utils/axios";
import AiProgressbar from "../utility_components/ai-progressbar";

interface CompanyDocumentsProps {
  ticker: string | undefined;
  currencySymbol: string;
}

interface ValuationData {
  historical_ratios: HistoricalData,
  dcf: {
    dcf_price: number;
    stock_price: number;
  },
  peers: PeersData;
}

export default function CompanyValuation({ticker, currencySymbol}: CompanyDocumentsProps) {
  const [valuationData, setValuationData] = useState<ValuationData>({
    historical_ratios: {
      priceToEarnings: {mean: 0, historical: []},
      priceToBook: {mean: 0, historical: []},
      priceToSales: {mean: 0, historical: []},
      priceToFreeCashFlows: {mean: 0, historical: []}
    },
    dcf: {dcf_price: 0, stock_price: 0},
    peers: {
      priceToEarnings: {mean: 0, peers: []},
      priceToBook: {mean: 0, peers: []},
      priceToSales: {mean: 0, peers: []},
      priceToFreeCashFlows: {mean: 0, peers: []}
    }
  });
  const [valuationAnalysis, setValuationAnalysis] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);

  useEffect(() => {
    const getCompanyValuation = async () => {
      try {
        setIsLoading(true);
        const valuationMetricsResponse = await axios.get<any>(`/api/companies/valuation/${ticker}`);
        setValuationData(valuationMetricsResponse.data);
      } catch (error) {
        console.error("Error fetching company valuation:", error);
      }
      setIsLoading(false);
    }

    const getCompanyAiAnalysis = async () => {
      try {
        setIsLoadingAnalysis(true);
        const valuationAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=valuation`);
        setValuationAnalysis(JSON.parse(valuationAnalysisResponse.data.answer));
      } catch (error) {
        console.error("Error fetching company valuation AI analysis:", error);
      }
      setIsLoadingAnalysis(false);
    }

    getCompanyValuation();
    getCompanyAiAnalysis();
  }, [ticker]);

  if (isLoading) {
    return <LinearProgress/>;
  }
  return (
    <>
      {/*
      {valuationData.dcf && (
        <CompanyValuationIntrinsicChart dcfData={valuationData.dcf} currencySymbol={currencySymbol}/>
      )}
      */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={6} sx={{mb: 1}}>
            {isLoadingAnalysis && <AiProgressbar/>}
            {!isLoadingAnalysis &&  (
              <CompanyValuationAiAnalysis ticker={ticker} valuationAnalysis={valuationAnalysis}/>)
            }
          </Grid>
          <Grid item xs={12} md={6} xl={6} sx={{mb: 1}}>
            <Stack direction="column">
              {valuationData.historical_ratios && (
                <Box sx={{mb: 2}}>
                  <CompanyValuationHistoricalChart
                    priceToEarnings={valuationData.historical_ratios.priceToEarnings}
                    priceToBook={valuationData.historical_ratios.priceToBook}
                    priceToSales={valuationData.historical_ratios.priceToSales}
                    priceToFreeCashFlows={valuationData.historical_ratios.priceToFreeCashFlows}
                  />
                </Box>
              )}

              {valuationData.peers && (
                <Box>
                  <CompanyValuationPeersComparison ticker={ticker} peersData={valuationData.peers}/>
                </Box>
              )}
            </Stack>
          </Grid>

        </Grid>
    </>
  );
}
