
// Sentry
import * as Sentry from "@sentry/react";
import { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import App from './App';

declare global {
  interface Window {
    hj: any;
    _hjSettings: {
      hjid: number;
      hjsv: number;
    };
  }
}

const env = process.env.NODE_ENV;

Sentry.init({
  environment: env,
  dsn: "https://5e605cf62957cf2bd4e24415a4000858@o4506517395406848.ingest.sentry.io/4506517398618112",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/api\.valuehunter\.net/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: env === 'production' ? 1.0 : 0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: env === 'production' ? 1.0: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: env === 'production' ? 1.0: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const initializeHotjar = () => {
  (function(h: any, o: Document, t: string, j: string) {
    h.hj = h.hj || function(...args: any[]) { (h.hj.q = h.hj.q || []).push(args); };
    h._hjSettings = { hjid: 5104251, hjsv: 6 };

    const head = o.getElementsByTagName('head')[0];
    if (head) {
      const script = o.createElement('script');
      script.async = true;
      script.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      head.appendChild(script);
    }
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};

const Root = () => {
  useEffect(() => {
    if (env === 'production') {
      initializeHotjar();
    }
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  );
};

// Render the app
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Root />);
