export const featureDescriptions = {
  scoringAlgorithm: 'A proprietary algorithm that scores every company on 4 factors - Valuation, Financial Strength, Growth Potential, and Efficiency. It is based purely on numerical data and can give you a quick overview of the fundamentals.',
  screener: 'Filter companies based on their scores and discover new companies tailored to your investment strategy.',
  financialStatementsFeaturePreview: 'Get a concise analysis and overview of the financial reports of each company with a single click.',
  financialStatementsPricing: 'Access financial statements like Income Statement, Balance Sheet, and Cashflow Statement for each company for 5 years in the past.',
  latestNews: "An overview of all recent news for each company summarized by topic. Get a quick overview of what's in the news about a given company without reading each news.",
  valuation: 'Gives you a verdict if the company is undervalued or overvalued, assessment of the main valuation ratios (P/E, P/B, etc.), and comparison to peers and historical values.',
  businessModel: 'Assessment of the business model and main strengths and weaknesses of this business. Revenue breakdown by geography and product for each company.',
  growthPotential: "An estimate of whether a company's business is more likely to grow or decline by developing two hypotheses - growth and decline - and taking into account analysts predictions.",
  financialStrength: "Easy to understand assessment of each company's financial stability by analysing current and historical debt and liquidity.",
  latestEarningsCall: "Summarized information from the latest earnings call like financial performance, management's guidance and future outlook, business oportunities ahead, main risks and risk management, and more.",
}
