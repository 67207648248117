// @mui
import React, {useEffect, useState} from "react";
import {Box, Grid, LinearProgress} from "@mui/material";
import Button from "@mui/material/Button";

import Iconify from "../iconify";

interface CompanyDocumentsProps {
  ticker: string | undefined;
  latest_annual_url: string | null;
  latest_quarterly_url: string | null
}

export default function CompanyPublicDocuments({ticker, latest_annual_url, latest_quarterly_url}: CompanyDocumentsProps) {
  const [isLoading, setIsLoading] = useState(false); // new state variable for loading status

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Grid container spacing={1}>
        {latest_annual_url && (
          <Grid item xs={12}>
            <Button variant="outlined"
                    onClick={() => latest_annual_url && window.open(latest_annual_url, '_blank')}
                    sx={{width: "180px"}}>
              <Box sx={{display: 'flex', alignItems: 'left', width: '100%'}}>
                <Iconify icon="tabler:external-link" sx={{mr: 1}}/>
                <span>Annual Report</span>
              </Box>
            </Button>
          </Grid>
        )}

        {latest_quarterly_url && (
          <Grid item xs={12}>
            <Button variant="outlined"
                    onClick={() => latest_quarterly_url && window.open(latest_quarterly_url, '_blank')}
                    sx={{width: "180px"}}>
              <Box sx={{display: 'flex', alignItems: 'left', width: '100%'}}>
                <Iconify icon="tabler:external-link" sx={{mr: 1}}/>
                <span>Quarterly Report</span>
              </Box>
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
