import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Chart from "../chart/chart";
import {useChart} from "../chart";
import {calculateChangePercentage} from "../company/financials-utils";


// make width and height optional
export default function PercentageChange({leastRecentPrice, mostRecentPrice, fontSize = "0.8rem"}: {
  leastRecentPrice: number,
  mostRecentPrice: number
  fontSize?: string
}) {

  const theme = useTheme();
  const percentage = calculateChangePercentage(mostRecentPrice, leastRecentPrice);
  const color = percentage > 0 ? theme.palette.success.main : theme.palette.error.main;
  const symbol = percentage > 0 ? '▲' : '▼';
  return (
    <Typography sx={{fontSize, color}}>{`${symbol}${percentage.toFixed(1)}%`}</Typography>
  )
}
