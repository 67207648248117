// @mui
import Button from '@mui/material/Button';

// GA
// stripe
import {loadStripe} from "@stripe/stripe-js";

// assets
// components
import React from "react";
import axios from "../../utils/axios";
import {useAuthContext} from "../../auth/hooks";
import {useRouter} from "../../routes/hooks";
import {paths} from "../../routes/paths";
import {gaAddToCart, gaBeginCheckout} from "../../utils/ga";


// ----------------------------------------------------------------------

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

export default function BecomePremiumButton() {
  const {authenticated, user} = useAuthContext();
  const isPremium = user?.subscription && user.subscription?.is_active;
  const router = useRouter();

  const handleButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent the default action
    event.preventDefault();
    gaAddToCart();
    gaBeginCheckout();
    if (authenticated) {
      handleAuthenticatedButtonClick(event);
    } else {
      handleNotAuthenticatedButtonClick(event);
    }
  };

  const handleAuthenticatedButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    // Get Stripe.js instance
    const stripe = await stripePromise;

    const priceLookupKey = process.env.REACT_APP_MONTHLY_PREMIUM_PRICE_LOOKUP
    console.log('priceLookupKey', priceLookupKey)

    try {
      const response = await axios.post('/api/create-checkout-session', {lookup_key: priceLookupKey});
      const session = response.data;

      // When the Checkout Session is created, redirect to the checkout page
      if (stripe) {
        await stripe.redirectToCheckout({sessionId: session.sessionId});
      } else {
        console.error('Stripe.js has not loaded yet.');
        alert('Stripe.js has not loaded yet. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      alert('Failed to start the checkout process. Please try again.');
    }
  };

  const handleNotAuthenticatedButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    // redirect to register page
    router.push(`${paths.auth.jwt.register}?afterPlans=1`);
  };

  return (
    <>
      {isPremium ? <Button variant="contained" sx={{
          mt: 2,
          width: '60%',
          py: 1.5
        }} disabled>Current Plan</Button>
        :
        <Button variant="contained" sx={{
          mt: 2,
          width: '60%',
          py: 1.5
        }} onClick={handleButtonClick}>Start Free Trial</Button>
      }
    </>
  );
}
