import {featureDescriptions} from "../../utils/constants";

export const features = [
  {
    id: 0,
    title: 'Scoring Algorithm',
    description: featureDescriptions.scoringAlgorithm,
    image: '/assets/images/features/scoring75.gif'
  },
  {
    id: 0,
    title: 'Screener',
    description: featureDescriptions.screener,
    image: '/assets/images/features/screener75.gif'
  },
  {
    id: 0,
    title: 'Financial Statements',
    description: featureDescriptions.financialStatementsFeaturePreview,
    image: '/assets/images/features/financials.gif'
  },
  {
    id: 0,
    title: 'Latest News',
    description: featureDescriptions.latestNews,
    image: '/assets/images/features/news.gif'
  },
  {
    id: 0,
    title: 'Valuation',
    description: featureDescriptions.valuation,
    image: '/assets/images/features/valuation75.gif'
  },
  {
    id: 0,
    title: 'Business Model',
    description: featureDescriptions.businessModel,
    image: '/assets/images/features/business-model.png'
  },
  {
    id: 0,
    title: 'Growth Potential',
    description: featureDescriptions.growthPotential,
    image: '/assets/images/features/growth75.gif'
  },

  {
    id: 0,
    title: 'Financial Strength',
    description: featureDescriptions.financialStrength,
    image: '/assets/images/features/financial-strength.png'
  },
  {
    id: 0,
    title: 'Earnings Call',
    description: featureDescriptions.latestEarningsCall,
    image: '/assets/images/features/earnings75.gif'
  },
]


interface Sector {
  title: string;
  icon: string;
}

export const sectorsWithIcons: Sector[] = [
  {
    title: 'Basic Materials',
    icon: "lets-icons:materials"
  },
  {
    title: 'Communication Services',
    icon: "material-symbols:communication"
  },
  {
    title: 'Consumer Cyclical',
    icon: "material-symbols:shopping-bag-outline-sharp"
  },
  {
    title: 'Consumer Defensive',
    icon: "bx:bxs-store"
  },
  {
    title: 'Energy',
    icon: "fa6-solid:oil-well"
  },
  {
    title: 'Financial Services',
    icon: "ph:bank-fill"
  },
  {
    title: 'Healthcare',
    icon: "bx:bxs-first-aid"
  },
  {
    title: 'Industrials',
    icon: "bx:bxs-building"
  },
  {
    title: 'Real Estate',
    icon: "bx:bxs-home"
  },
  {
    title: 'Technology',
    icon: "hugeicons:chip"
  },
  {
    title: 'Utilities',
    icon: "bx:bxs-bolt"
  }
];
