import React from "react";
import SurveyQuestionFreeForm from "./survey_question_free_form";
import SurveyQuestionSingleChoice from "./survey_question_single_choice";
import SurveyQuestionMultipleChoice from "./survey_question_multiple_choice";


export default function SurveyQuestion({text, type, choices, response, setResponse}: {
  text: string,
  type: string,
  choices: {id: number, text: string}[],
  response: any,
  setResponse: (response: any) => void
}) {
  const renderContent = (
    <>
      {type === "open_text" && (
        <SurveyQuestionFreeForm text={text} response={response} setResponse={setResponse}/>
      )}
      {type === "single_choice" && (
        <SurveyQuestionSingleChoice text={text} choices={choices} response={response} setResponse={setResponse}/>
      )}
      {type === "multiple_choice" && (
        <SurveyQuestionMultipleChoice text={text} choices={choices} response={response || []} setResponse={setResponse}/>
      )}
    </>
  );
  return (
    <>
      {renderContent}
    </>
  );
}
