import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ReactMarkdown from "react-markdown";

import Iconify from "../iconify";
import AiProgressbar from "../utility_components/ai-progressbar";

interface CompanyDocumentsProps {
  aiAnalysis: AIAnalysisType;
}

interface AIAnalysisType {
  business_model_assessment: string,
  industry_assessment: string,
  pros: string[],
  cons: string[],
}

export default function CompanyBusinessAiAnalysis({aiAnalysis}: CompanyDocumentsProps) {
  if (!aiAnalysis.pros) {
    return <AiProgressbar/>;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{color: 'text.primary'}}>
              Business Assessment
            </Typography>
            <Typography variant="body2" sx={{
              color: 'text.primary',
            }}>
              <ReactMarkdown>
                {aiAnalysis?.business_model_assessment}
              </ReactMarkdown>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{color: 'text.primary'}}>
              Industry Assessment
            </Typography>
            <Typography variant="body2" sx={{
              color: 'text.primary',
            }}>
              <ReactMarkdown>
                {aiAnalysis?.industry_assessment}
              </ReactMarkdown>
            </Typography>
          </Grid>
        </Grid>


      </Grid>

      <Grid item xs={12}>
        <Stack direction="column" spacing={0.5} sx={{mt: 1, backdropFilter: 'blur(10px)'}}>
          <Typography variant="h6" sx={{color: 'text.primary'}}>
            Strengths
          </Typography>
          {aiAnalysis?.pros.map((text, index) => (
            <Box key={index}>
              <Stack direction="row" spacing={0.8}>
                <Box component="span" sx={{color: 'text.secondary'}}>
                  <Iconify
                    width={24}
                    icon='icon-park-twotone:success'
                    sx={{
                      color: 'success.main',
                    }}/>
                </Box>
                <Box component="span" sx={{color: 'text.secondary'}}>
                  <Typography variant="body2" sx={{
                    color: 'text.secondary',
                  }}>
                    {text}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          ))}
        </Stack>
        <Stack direction="column" spacing={0.5} sx={{mt: 1}}>
          <Typography variant="h6" sx={{color: 'text.primary'}}>
            Concerns
          </Typography>
          {aiAnalysis?.cons.map((text, index) => (
            <Box key={index}>
              <Stack direction="row" spacing={0.8}>
                <Box component="span" sx={{color: 'text.secondary'}}>
                  <Iconify
                    width={24}
                    icon='ic:twotone-error'
                    sx={{
                      color: 'error.main',
                    }}/>
                </Box>
                <Box component="span" sx={{color: 'text.secondary'}}>
                  <Typography variant="body2" sx={{
                    color: 'text.secondary',
                  }}>
                    {text}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}
