import {ApexOptions} from 'apexcharts';
import React, {useState, useCallback, useEffect} from 'react';
// @mui
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import CardHeader from '@mui/material/CardHeader';
import ButtonBase from '@mui/material/ButtonBase';
import Card, {CardProps} from '@mui/material/Card';
import Typography from "@mui/material/Typography";
// components
import Iconify from 'src/components/iconify';
import Chart, {useChart} from 'src/components/chart';
import CustomPopover, {usePopover} from 'src/components/custom-popover';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  currencySymbol: string;
  chart: {
    categories?: string[];
    colors?: string[][];
    series: {
      year: string;
      data: {
        name: string;
        data: any;
      }[];
    }[];
    options?: ApexOptions;
  };
}

export default function PriceChartInternal({
                                             title,
                                             subheader,
                                             currencySymbol,
                                             chart,
                                             ...other
                                           }: Props) {
  const theme = useTheme();

  const {
    colors = [
      [theme.palette.primary.lighter, theme.palette.primary.light],
      [theme.palette.warning.lighter, theme.palette.warning.light],
    ],
    categories,
    series,
    options,
  } = chart;

  const popover = usePopover();

  const [seriesData, setSeriesData] = useState('1y');
  const [percentChange, setPercentChange] = useState('');

  const chartOptions = useChart({
      colors: colors.map((colr) => colr[1]),
      fill: {
          type: 'gradient',
          gradient: {
              colorStops: colors.map((colr) => ({
                  offset: 0,
                  color: colr[0],
                  opacity: 1 // Assuming opacity is required; adjust as necessary
              })).concat(colors.map((colr) => ({
                  offset: 100,
                  color: colr[1],
                  opacity: 1 // Assuming opacity is required; adjust as necessary
              }))),
          },
      },
      tooltip: {
          x: {
              formatter(value) {
                  // Convert the value to a Date instance
                  const date = new Date(value);

                  // Format the date in format 28 May 2021
                  return date.toLocaleDateString('en-GB', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric'
                  });
              }
          }
      },
      xaxis: {
          categories,
      },
      ...options,
  });

  const calculatePercentChange = useCallback((dataLabel: string) => {
    const seriesForYear = series.find((item) => item.year === dataLabel);
    const seriesForYearData = seriesForYear?.data[0]?.data;

    if (!seriesForYearData || seriesForYearData.length === 0) {
      return;
    }
    const firstDataPoint = seriesForYearData[seriesForYearData.length - 1][1];
    const lastDataPoint = seriesForYearData[0][1];
    const percentChangeLocal = ((lastDataPoint - firstDataPoint) / firstDataPoint) * 100;
    setPercentChange(`${percentChangeLocal.toFixed(2)}%`);
  }, [series]);

  const handleChangeSeries = useCallback((newValue: string) => {
    popover.onClose();
    setSeriesData(newValue);

    // calculate percent change between last and first data points
    calculatePercentChange(newValue);
  }, [popover, setSeriesData, calculatePercentChange]);


  useEffect(() => {
    calculatePercentChange('1y');
  }, [calculatePercentChange]);

  return (
    <>
      <Card {...other}>
        <CardHeader
          title={title}
          subheader={
            <>
              <Typography
                variant="body2"
                component="span"
                color="text.secondary"
              >
                <b>{currencySymbol}{series[0].data[0].data[0][1]}</b> (<Typography variant="body2"
                                                                                   component="span"
                                                                                   sx={{color: percentChange.startsWith('-') ? 'red' : 'green'}}>
                {percentChange} {percentChange.startsWith('-') ? ' ▼' : ' ▲'}
              </Typography>)
              </Typography>
            </>

          }
          action={
            <ButtonBase
              onClick={popover.onOpen}
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                bgcolor: 'background.neutral',
              }}
            >
              {seriesData}

              <Iconify
                width={16}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ml: 0.5}}
              />
            </ButtonBase>
          }
        />

        {
          series.map((item) => (
            <Box key={item.year} sx={{mt: 3, mx: 3}}>
              {item.year === seriesData && (
                <Chart dir="ltr" type="line" series={item.data} options={chartOptions} height={250}/>
              )}
            </Box>
          ))
        }
      </Card>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{width: 140}}>
        {series.map((option) => (
          <MenuItem
            key={option.year}
            selected={option.year === seriesData}
            onClick={() => handleChangeSeries(option.year)}
          >
            {option.year}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  )
    ;
}
