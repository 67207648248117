// layouts
import {RouteWithHeader} from "../../layouts/custom-header/route-with-header";
import DashboardLayout from "../../layouts/dashboard";


export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (

        <RouteWithHeader>
          <DashboardLayout/>
        </RouteWithHeader>
    ),
  },
];
