import ReactGA from "react-ga4";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LinearProgress from "@mui/material/LinearProgress";
import AutoAwesomeTwoTone from '@mui/icons-material/AutoAwesomeTwoTone';
import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';

import ReactMarkdown from 'react-markdown';


import {paths} from "../../routes/paths";
import {useAuthContext} from "../../auth/hooks";
import axios, {endpoints} from "../../utils/axios";
import {AIAnalysisType, AIAnalysisTypesEnum} from "./types";
import {ANALYSES} from "./preview-analysis-constants";
import NoCreditsDialog from "../../layouts/credits/no-credits-dialog";


export default function AIAnalysis({ticker, analysisType}: {
  ticker: string | undefined,
  analysisType: AIAnalysisTypesEnum
}) {
  const {authenticated} = useAuthContext();
  const [aiAnalysis, setAiAnalysis] = useState<AIAnalysisType | null>(null);
  const [aiAnalysisFetching, setAiAnalysisFetching] = useState(false);
  const [aiAnalysisGenerating, setAiAnalysisGenerating] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [noCreditsDialogOpen, setNoCreditsDialogOpen] = useState(false);


  const getAiAnalysis = async () => {
    try {
      setDialogOpen(true);
      setAiAnalysisFetching(true);
      let result = null;
      if (authenticated) {
        result = await axios.get<any>(
          `${endpoints.ai_analysis}/${ticker}?analysis_type=${analysisType}`
        );
      } else {
        result = {
          data: {
            answer: ANALYSES[analysisType],
            type: analysisType,
            datetime_generated: ""
          }
        };
      }
      setAiAnalysis(result.data);
    } catch (error) {
      if (error.detail === "Not enough credits") {
        setNoCreditsDialogOpen(true);
      } else {
        console.error("Error fetching AI analysis:", error);
      }
    } finally {
      setAiAnalysisFetching(false);
    }
  };

  const regenerateAiAnalysis = async () => {
    try {
      setAiAnalysis(null);
      setAiAnalysisGenerating(true);
      const result = await axios.post<any>(
        `${endpoints.ai_analysis}/${ticker}?analysis_type=${analysisType}`,
      );
      setAiAnalysis(result.data);
    } catch (error) {
      if (error.detail === "Not enough credits") {
        setNoCreditsDialogOpen(true);
      } else {
        console.error("Error fetching AI analysis:", error);
      }
    } finally {
      setAiAnalysisGenerating(false);
    }
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
    setAiAnalysis(null);
  }

  const handleSignUpClick = () => {
    ReactGA.event("AI SignUpClick", {
      category: "New Feature Sign up",
      action: "Click",
      label: "AI Analysis"
    });
  }
  return (
    <Box>
      <Button variant="outlined" color="primary" endIcon={<TipsAndUpdatesTwoToneIcon/>}
              onClick={getAiAnalysis}>
        AI Analysis
      </Button>

      <Dialog open={dialogOpen} onClose={() => handleDialogClose()}>
        <DialogTitle>AI Analysis

          <IconButton
            edge="end"
            color="inherit"
            onClick={() => {
              setDialogOpen(false);
              setAiAnalysis(null);
            }}
            aria-label="close"
            sx={{position: 'absolute', right: 14, top: 8}}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {aiAnalysisGenerating && (
            <>
              <LinearProgress/>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                Generating AI analysis... Hold tight!
              </Typography>
            </>
          )}
          {aiAnalysis && authenticated && (
              <Typography variant="body2">
                <ReactMarkdown>{aiAnalysis.answer}</ReactMarkdown>
              </Typography>
          )}
          {aiAnalysis && !authenticated && (
            <>
              <Alert
                variant="outlined"
                severity="warning"
                sx={{mt: 2, width: 1}}
              >
                You are currently a guest and what you see below is <strong>preview
                analysis</strong> of another company.
                Please <Link href={`${paths.auth.jwt.login}?returnTo=/companies/${ticker}`}
                      onClick={handleSignUpClick} variant="body2">
                  <strong><u>login</u></strong></Link> or <Link href={paths.auth.jwt.register} onClick={handleSignUpClick} variant="body2">
                  <strong><u>sign up</u></strong> </Link>
                for free to get full access to the AI Analysis feature.
              </Alert>
              <Typography variant="body2">
                <ReactMarkdown>{aiAnalysis.answer}</ReactMarkdown>
              </Typography>
            </>
          )}


          {!aiAnalysis && aiAnalysisFetching && (
            <>
              <LinearProgress/>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                Fetching generated AI analysis...
              </Typography>
            </>
          )}

          {!aiAnalysis && !aiAnalysisFetching && !aiAnalysisGenerating && (
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
              No AI analysis generated yet. Click on the button to generate one!
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{justifyContent: 'space-between'}}>

          <Button onClick={() => setDialogOpen(false)}> Close</Button>
          {!aiAnalysis && authenticated && (
            <Button variant="text" color="primary" onClick={regenerateAiAnalysis}
                    disabled={aiAnalysisGenerating || aiAnalysisFetching || !authenticated}>
              <AutoAwesomeTwoTone sx={{mr: 1}}/>Generate
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <NoCreditsDialog open={noCreditsDialogOpen} onClose={() => setNoCreditsDialogOpen(false)}/>
    </Box>
  );
}
