import React from "react";
import {Box, Divider} from "@mui/material";
import Stack from "@mui/material/Stack";
import NewsNews, {NewsSingleType} from "./news_news";

export interface NewsMultipleType {
  news: NewsSingleType[];
}


export default function NewsContainer({news}: NewsMultipleType) {
  return (
    <Box>
      <Stack direction="column" spacing={2} sx={{mt: 2}}>
        {news.map((newsItem, idx) => (
          <Box key={idx}>
            <NewsNews {...newsItem}/>
            <Divider/>
          </Box>
        ))}
      </Stack>
    </Box>
  )
}
