import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {GuestGuard} from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
import CompactLayout from 'src/layouts/compact';

// components
import {SplashScreen} from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const VerifyClassicPage = lazy(() => import('src/pages/auth/verify/verify'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot_password'));
const ResetPasswordPage = lazy(() => import('src/pages/auth/jwt/reset_password'));
const GoogleCallback = lazy(() => import('src/sections/auth/jwt/jwt-login-google-callback'));

// ----------------------------------------------------------------------

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen/>}>
        <Outlet/>
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <JwtLoginPage/>
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="Register">
          <JwtRegisterPage/>
        </AuthClassicLayout>
      ),
    },
     {
      path: 'google/callback',
      element: (
          <GoogleCallback/>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: 'forgot-password', element: <ForgotPasswordPage /> },
        { path: 'reset-password', element: <ResetPasswordPage /> },
        // { path: 'new-password', element: <NewPasswordClassicPage /> },
        { path: 'verify', element: <VerifyClassicPage /> },
      ],
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
