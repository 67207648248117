// @mui
import React, {useCallback, useState} from "react";
import {Box, Card, Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ButtonBase from "@mui/material/ButtonBase";
import numeral from "numeral";

import Chart, {useChart} from 'src/components/chart';
import CustomPopover, {usePopover} from "../custom-popover";
import Iconify from "../iconify";


export interface HistoricalFinancialStabilityData {
  currentRatio: {
    mean: number;
    historical: {
      date: string;
      value: number;
    }[]
  },
  quickRatio: {
    mean: number;
    historical: {
      date: string;
      value: number;
    }[]
  },
  debtEquityRatio: {
    mean: number;
    historical: {
      date: string;
      value: number;
    }[]
  },
  interestCoverage: {
    mean: number;
    historical: {
      date: string;
      value: number;
    }[]
  }
}

export default function CompanyFinancialStrengthHistoricalChart({
                                                                  currentRatio,
                                                                  debtEquityRatio,
                                                                  interestCoverage,
                                                                  quickRatio
                                                                }: HistoricalFinancialStabilityData) {
  const theme = useTheme();
  const primaryLight = theme.palette.primary.light;
  const primaryMain = theme.palette.primary.main;
  const grey400 = theme.palette.grey[400];

  const popover = usePopover();
  const [currentSeriesKey, setCurrentSeriesKey] = useState('Debt To Equity');
  const [currentSeriesField, setCurrentSeriesField] = useState('priceToEarnings');

  const seriesKeyToField: { [key: string]: { mean: number, historical: any } } = {
    "Current Ratio": currentRatio,
    "Quick Ratio": quickRatio,
    "Debt To Equity": debtEquityRatio,
    "Interest Coverage": interestCoverage,
  }

  const handleChangeSeries = useCallback((newValue: string) => {
    popover.onClose();
    setCurrentSeriesKey(newValue);
  }, [popover]);

  const financialStrengthChart = useChart({
    series: [
      {
        name: currentSeriesKey,
        // @ts-ignore
        data: seriesKeyToField[currentSeriesKey].historical.map(data => data.value),
      },
    ],
    chart: {
      type: 'line',
      height: 230,
      toolbar: {
        autoSelected: 'pan',
        show: false
      }
    },
    colors: [primaryLight],
    // stroke: {
    //   width: 3
    // },
    tooltip: {
      fillSeriesColor: false,
      x: {
        formatter(val: number) {
          // Convert the timestamp to a Date object
          const date = new Date(val);
          // Format the date
          return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          });
        }
      },
      y: {
        formatter(val: number) {
          return numeral(val).format('0.00a');
        }
      }
    },
    dataLabels: {
      enabled: false,
      // format labels for big numbers
      formatter(val: number) {
        return numeral(val).format('0.00a');
      }
    },
    fill: {
      opacity: 1,
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: 'datetime',
      categories: seriesKeyToField[currentSeriesKey].historical.map((data: {
        date: any;
      }) => data.date),
      tooltip: {
        enabled: true,
        formatter(val: string, opts?: object) {
          // Convert the timestamp to a Date object
          const date = new Date(val);
          // Format the date
          return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          });
        }
      }
    },
    annotations: {
      yaxis: [
        {
          y: seriesKeyToField[currentSeriesKey].mean,
          borderColor: grey400,
          borderWidth: 2,
          opacity: 0.2,
          fillColor: '#B3F7CA',
          label: {
            position: 'right',
            style: {
              fontSize: '14px',
            },
            text: `Avg: ${seriesKeyToField[currentSeriesKey].mean}`
          }
        }
      ]
    },
    yaxis: {
      labels: {
        formatter(val: number) {
          return numeral(val).format('0.00a');
        }
      }
    },
  });

  if (!seriesKeyToField[currentSeriesKey].historical.length) {
    return (
      <Box sx={{mb: 3}}>
        <Typography variant="h6" sx={{color: 'text.primary'}}>
          Historical Data
        </Typography>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          No historical data available
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <Box>
        <Card>
          <CardHeader
            title="Financial Strength Historical"
            subheader={
              <Typography
                variant="body2"
                component="span"
                color="text.secondary"
              >
                {currentSeriesKey}
              </Typography>
            }
            action={
              <ButtonBase
                onClick={popover.onOpen}
                sx={{
                  pl: 1,
                  py: 0.5,
                  pr: 0.5,
                  borderRadius: 1,
                  typography: 'subtitle2',
                  bgcolor: 'background.neutral',
                }}
              >
                {currentSeriesKey}
                <Iconify
                  width={16}
                  icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                  sx={{ml: 0.5}}
                />
              </ButtonBase>
            }
          />
          <Chart
            type="line"
            dir="ltr"
            series={financialStrengthChart.series}
            options={financialStrengthChart}
            height={250}
          />
        </Card>
      </Box>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{width: 140}}>
        {Object.keys(seriesKeyToField).map((key) => (
          <MenuItem
            key={key}
            selected={key === currentSeriesKey}
            onClick={() => handleChangeSeries(key)}
          >
            {key}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
