import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";
import {TransitionProps} from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import LinearProgress from '@mui/material/LinearProgress';
import SurveyQuestion from './survey_question';
import SurveyFinal from './survey_final';
import SurveyEntry from './survey_entry';
import axios, {endpoints} from '../../utils/axios';
import {useAuthContext} from '../../auth/hooks';


const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

interface SurveyType {
  id: number;
  title: string;
  questions: {
    id: number;
    type: string;
    text: string;
    choices: {
      id: number;
      text: string;
    }[];
  }[];

}

export default function Survey() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeScreen, setActiveScreen] = useState("entry");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [surveySubmitted, setSurveySubmitted] = useState(false);
  const [surveySubmissionError, setSurveysubmissionError] = useState(false);
  const [survey, setSurvey] = useState<SurveyType>({
    id: 0,
    title: '',
    questions: [],
  });
  const [surveyResponses, setSurveyResponses] = useState<{}>({});

  // const openDialog = () => {
  //   setDialogOpen(true);
  //   ReactGA.event("SurveyOpened", {
  //     category: "Survey",
  //     action: "Open survey",
  //   });
  // }
  const {authenticated} = useAuthContext();


  useEffect(() => {
    const getSurvey = async () => {
      if (!authenticated)
        return;

      try {
        const result = await axios.get(`${endpoints.survey.get}/1`);
        const data = result.data || {questions: []};
        if (result.data) {
          setDialogOpen(true);
        }
        setSurvey(data);

        // build initial responses object question.id: null
        setSurveyResponses(Object.fromEntries(data.questions.map((question: any) => [question.id, null])));
        console.log('Survey:', result.data);
        console.log('Survey Responses:', Object.fromEntries(data.questions.map((question: any) => [question.id, null])));
      } catch (error) {
        console.error('Error survey:', error);
      } finally {
        // setAiAnalysisFetching(false);
      }

    };
    getSurvey();
  }, [authenticated]);

  const progress = survey?.questions?.length > 0 ? (currentQuestionIndex / survey.questions.length) * 100 : 0;

  const onEntryClose = async () => {

    const data = {'survey_id': survey.id};
    setDialogOpen(false);
    try {
      const result = await axios.post(endpoints.survey.skip, data);
    } catch (error) {
      console.error('Error submitting survey skip:', error);
    } finally {
      // setAiAnalysisFetching(false);
    }
  };

  const prepareResponses = (resps: any) =>
    // reformat each response in the following format:
    // {
    //     "question_id": 1,
    //     "response_text": "This is an open text response.",
    //     "choices_id": null,
    //     "survey_id": 1,
    //     "is_skipped": false
    // }
    // if the question is free form, response_text will be the text entered by the user, and choices_id will be null
    // if the question is a single choice question, choices_id will be the id of the selected choice, and response_text will be null
    // if the question is a multiple choice question, choices_id will be an array of selected choice ids, and response_text will be null

    Object.entries(resps).map(([questionId, response]) => {
      const question = survey.questions.find((q) => q.id === parseInt(questionId, 10));
      if (question) {
        if (question.type === 'open_text') {
          return {
            question_id: question.id,
            response_text: response,
            choices_id: null,
            survey_id: survey.id,
            is_skipped: false,
          };
        }
        if (question.type === 'single_choice') {
          return {
            question_id: question.id,
            response_text: null,
            choices_id: response,
            survey_id: survey.id,
            is_skipped: false,
          };
        }
        if (question.type === 'multiple_choice') {
          return {
            question_id: question.id,
            response_text: null,
            choices_id: response,
            survey_id: survey.id,
            is_skipped: false,
          };
        }
      }
      return null;
    })


  const onSurveySubmit = async () => {
    const preparedResponses = prepareResponses(surveyResponses);
    try {
      const result = await axios.post(endpoints.survey.responses, preparedResponses);
      console.log('Survey submit result:', result.data);
    } catch (error) {
      console.error('Error submitting survey:', error);
      setSurveysubmissionError(true);
    } finally {
      setDialogOpen(false);
      setSurveySubmitted(true);
    }
  }

  const entryButtons = (
    <>
      <Button
        variant="contained"
        onClick={() => onEntryClose()}
      >
        {`I don't need free credits`}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setCurrentQuestionIndex(0)}
      >
        {`Let's go!`}
      </Button>
    </>
  );

  const questionButtons = (
    <>
      {currentQuestionIndex !== 0 && (
        <Button
          variant="contained"
          onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
        >
          Back
        </Button>
      )}

      {/*
        create space between the "Back" button and the other buttons.
        */}
      <Box sx={{flexGrow: 1}}/>

      {currentQuestionIndex <= survey.questions.length - 1 && (
        // make "Next" button disabled if the user hasn't selected an answer
        <Button
          variant="contained" color="primary" size="large"
          onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}
          // @ts-ignore
          disabled={!surveyResponses[survey.questions[currentQuestionIndex]?.id] || surveyResponses[survey.questions[currentQuestionIndex]?.id].length === 0}
        >
          Next
        </Button>
      )
      }
    </>
  );

  const finalButtons = <>
    <Button
      variant="contained"
      onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
    >
      Back
    </Button>
    <Button
      variant="contained" color="primary" size="large"
      onClick={() => onSurveySubmit()}
    >
      Submit
    </Button>
  </>;

  const renderSnackbar = (
    <>
      {!surveySubmissionError && (
        <Snackbar open={surveySubmitted} autoHideDuration={15000}
                  onClose={() => setSurveySubmitted(false)}>
          <Alert onClose={() => setSurveySubmitted(false)} severity="success" sx={{width: '100%'}}>
            Thank you for your feedback! You can now use your free credits!
          </Alert>
        </Snackbar>)
      }
      {
        surveySubmissionError && (
          <Snackbar open={surveySubmitted} autoHideDuration={15000}
                    onClose={() => setSurveySubmitted(false)}>
            <Alert onClose={() => setSurveySubmitted(false)} severity="error" sx={{width: '100%'}}>
              Error submitting survey. Please try again later.
            </Alert>
          </Snackbar>)
      }
    </>
  )


  const setResponse = (response: any) => {
    setSurveyResponses({...surveyResponses, [survey.questions[currentQuestionIndex].id]: response});
  };
  useEffect(() => {
    if (currentQuestionIndex === -1) {
      setActiveScreen("entry");
    } else if (currentQuestionIndex < survey.questions.length) {
      setActiveScreen("question");
    } else {
      setActiveScreen("final");
    }
  }, [currentQuestionIndex, survey.questions.length]);

  // const renderContent = survey.questions.length > 0 ?  : (
  //   <LinearProgress />
  // );

  // @ts-ignore
  return (
    <>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          {survey.title}
        </DialogTitle>
        {currentQuestionIndex < survey.questions.length && (
          <LinearProgress variant="determinate" value={progress} sx={{width: '100%'}}/>
        )}
        <DialogContent
          sx={{
            height: '400px',
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {activeScreen === "entry" && <SurveyEntry/>}
          {activeScreen === "question" && (
            <SurveyQuestion text={survey.questions[currentQuestionIndex]?.text}
                            type={survey.questions[currentQuestionIndex]?.type}
                            choices={survey.questions[currentQuestionIndex]?.choices}
              // @ts-ignore
                            response={surveyResponses[survey.questions[currentQuestionIndex]?.id]}
                            setResponse={setResponse}
            />)}
          {activeScreen === "final" && <SurveyFinal/>}
        </DialogContent>
        <DialogActions sx={{justifyContent: 'space-between'}}>
          {activeScreen === "entry" && (
            entryButtons
          )}
          {activeScreen === "question" && (
            questionButtons
          )}
          {activeScreen === "final" && (
            finalButtons
          )}
        </DialogActions>
      </Dialog>
      {renderSnackbar}
    </>
  );
}
