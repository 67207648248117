// @mui
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, {useContext, useState} from "react";
// routes
import {useRouter} from 'src/routes/hooks';
// hooks
// auth
import {useAuthContext} from 'src/auth/hooks';
// components
import CustomPopover, {usePopover} from 'src/components/custom-popover';
import {AuthContext} from "../../auth/context/jwt";
import {UserCredits} from "../chat/types";
import axios, {endpoints} from "../../utils/axios";
import Label from '../../components/label';
import ManagePlanButton from "../../components/subscription/manage-plan-button";
import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

interface AccountPopoverProps {
  userCredits: UserCredits;
}


export default function AccountPopover() {
  const router = useRouter();

  const {user, authenticated} = useContext(AuthContext);

  // get ref to the avatar
  const avatarRef = React.useRef<HTMLDivElement>(null);
  const [userCredits, setCredits] = useState<UserCredits>({credits: 0, next_renewal: ""});


  const {logout} = useAuthContext();

  const popover = usePopover();

  const refreshCredits = async () => {
    const result = await axios.get<UserCredits>(endpoints.user_credits, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const {credits, next_renewal} = result.data;
    setCredits({credits, next_renewal});
  };

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  const openPopover = (event: React.MouseEvent<HTMLElement>) => {
    popover.onOpen(event);
    refreshCredits();
  }

  return (
    // click on Avatar when clicked on the box
    <Box sx={{
      cursor: "pointer",
      height: '60px',
      width: '100%',
      // borderTop: (theme) => `2px solid ${theme.palette.background.paper}`,
      '&:hover': {
        backgroundColor: (theme) => alpha(theme.palette.action.hover, 0.1),
      },
    }}
    >
      <Stack direction="row"
             spacing={2} alignItems="center"
             onClick={openPopover}
             justifyContent="center" sx={{m: 0, height: '100%', width: '100%'}}>
        <Avatar
          className="user-avatar"
          src="/assets/avatars/avatar_5.jpg"
          alt={user?.displayName}
          ref={avatarRef}

          sx={{
            width: 40,
            height: 40,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
        {/*
         <Stack spacing={0}>
          <Box sx={{fontSize: 13}}><b>{user?.first_name} {user?.last_name}</b></Box>
          <Box sx={{fontSize: 13}}>credits left: <b>{userCredits.credits.toFixed(2)}</b></Box>
          <Box sx={{fontSize: 13}}>next renewal: <b>{new Date(userCredits.next_renewal).toLocaleDateString()}</b></Box>
        </Stack>
        */}
      </Stack>

      <CustomPopover hiddenArrow
                     open={popover.open}
                     onClose={popover.onClose}
                     anchorEl={avatarRef.current}
                     sx={{width: 230, p: 0}}
                     anchorOrigin={{
                       vertical: 'bottom',
                       horizontal: 'center',
                     }}
                     transformOrigin={{
                       vertical: 'top',
                       horizontal: 'right',
                     }}
      >
        <Box sx={{p: 2, pb: 1.5}}>
          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle2" noWrap>
              ValueHunter
            </Typography>
            <Box sx={{position: 'relative'}}>
              {user?.subscription?.status === 'active' && (
                <Label sx={{position: 'absolute', bottom: 6}} color="success"> Premium </Label>)}
              {user?.subscription?.status === 'trialing' && (
                <Label sx={{position: 'absolute', bottom: 6}} color="primary"> Trial </Label>)}
              {user?.subscription?.status !== 'active' && user?.subscription?.status !== 'trialing' && (
                <Label sx={{position: 'absolute', bottom: 6}} color="info"> Basic </Label>)}
            </Box>
          </Stack>
        </Box>
        <Divider sx={{borderStyle: 'dashed'}}/>
        <Box sx={{p: 2, pb: 1.5}}>
          <Typography variant="subtitle2" noWrap>
            {user?.first_name} {user?.last_name}
          </Typography>

          {user?.subscription?.status === 'active' && !user?.subscription?.cancel_at && (
            <>
              <Typography variant="body2" sx={{mt: 0.5, color: 'text.primary', fontSize: 13}} noWrap>
                plan status: <b>ACTIVE</b>
              </Typography>
              <Typography variant="body2" sx={{color: 'text.primary', fontSize: 13}} noWrap>
                next renewal: <b>{new Date(user.subscription.next_renewal).toLocaleDateString()}</b>
              </Typography>
            </>
          )}
          {user?.subscription?.status === 'trialing' && !user?.subscription?.cancel_at && (
            <>
              <Typography variant="body2" sx={{mt: 0.5, color: 'text.primary', fontSize: 13}} noWrap>
                plan status: <b>TRIAL</b>
              </Typography>
              <Typography variant="body2" sx={{color: 'text.primary', fontSize: 13}} noWrap>
                next renewal: <b>{new Date(user.subscription.next_renewal).toLocaleDateString()}</b>
              </Typography>
            </>
          )}
          {user?.subscription?.is_active === true && user?.subscription.cancel_at !== null && (
            <>
              <Typography variant="body2" sx={{mt: 0.5, color: 'text.primary', fontSize: 13}} noWrap>
                plan status: <b>CANCELED</b>
              </Typography>
              <Typography variant="body2" sx={{color: 'text.primary', fontSize: 13}} noWrap>
                valid until: <b>{new Date(user.subscription.cancel_at).toLocaleDateString()}</b>
              </Typography>
            </>
          )}
          {!user?.subscription && (
            <>
              <Typography variant="body2" sx={{mt: 0.5, color: 'text.primary', fontSize: 13}} noWrap>
                plan status: <b>BASIC</b>
              </Typography>
              <Typography variant="body2" sx={{color: 'text.primary', fontSize: 13}} noWrap>
                next renewal: <b>{new Date(userCredits.next_renewal).toLocaleDateString()}</b>
              </Typography>
            </>
          )}
          <Typography variant="body2" sx={{color: 'text.primary', fontSize: 13}} noWrap>
            AI credits: <b>{userCredits.credits.toFixed(2)}</b>
          </Typography>
        </Box>
        <Divider sx={{borderStyle: 'dashed'}}/>

        <Stack sx={{p: 1}}>
          {user?.subscription?.is_active ? (
            <MenuItem key="Manage-Plan">
              <ManagePlanButton/>
            </MenuItem>

          ) : (
            <MenuItem key="Premium" onClick={() => handleClickItem('/plans')}>
              Start Free Trial <Box component="span" sx={{ml: 1}}>🚀</Box>
            </MenuItem>
          )}
          <MenuItem key="Dashboard" onClick={() => handleClickItem('/dashboard')}>
            Dashboard
          </MenuItem>
          <MenuItem key="Screener" onClick={() => handleClickItem('/screener')}>
            Screener
          </MenuItem>

          <MenuItem key="DocumentAnalysis" onClick={() => handleClickItem('/document-analysis')}>
            <Stack direction="row">
              <Iconify icon="line-md:document-report" width={24}/> 10-K Analysis
              <Box sx={{position: 'relative'}}>
                <Label color="info" sx={{position: 'absolute', bottom: 15}}>Beta</Label>
              </Box>
            </Stack>
          </MenuItem>

        </Stack>

        <Divider sx={{borderStyle: 'dashed'}}/>

        <MenuItem
          onClick={handleLogout}
          sx={{m: 1, fontWeight: 'fontWeightBold', color: 'error.main'}}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </Box>

  );
}
