export const defaultGrowthScore = 1

export const shouldUseLocalLogo = new Set([
  "AMZN",
  "AAPL",
  "ASML"
])

export const currencyToSymbol = {
  "USD": "$",
  "EUR": "€",
  "JPY": "¥",
  "GBP": "£",
  "AUD": "A$",
  "CAD": "C$",
  "CHF": "Fr",
  "CNY": "¥",
  "SEK": "kr",
  "NZD": "NZ$",
  "KRW": "₩",
  "SGD": "S$",
  "NOK": "kr",
  "MXN": "Mex$",
  "INR": "₹",
  "RUB": "₽",
  "ZAR": "R",
  "TRY": "₺",
  "BRL": "R$",
  "TWD": "NT$",
  "DKK": "kr",
  "PLN": "zł",
  "THB": "฿",
  "IDR": "Rp",
  "HUF": "Ft",
  "CZK": "Kč",
  "ILS": "₪",
  "CLP": "CLP$",
  "PHP": "₱",
  "AED": "AED",
  "COP": "COL$",
  "SAR": "SR",
  "MYR": "RM",
  "RON": "L",
  "VND": "₫",
  "NGN": "₦",
  "IQD": "د.ع",
}
