import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function SurveyFinal() {

  return (
    <Box>
      <Typography variant="h6" textAlign="center" gutterBottom>Thank you for your feedback! 🙏</Typography>
      <Typography variant="h6" textAlign="center" gutterBottom>Please submit the survey to get your free credits! 🚀</Typography>
    </Box>
  );
}
