// @mui
import React from "react";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import {useNavigate} from "react-router-dom";
import IllustrationContainer from "../../components/subscription/illustration-container";
import {OrderCompleteIllustration} from "../../assets/illustrations";
import {gaPurchase} from "../../utils/ga";

export default function SubscriptionSuccess() {
  gaPurchase();

  const navigate = useNavigate();
  return (
    <Container
      sx={{
        pt: 5,
        pb: 5,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <IllustrationContainer
        title='Your free trial is activated!'
        description="You now have 10 free credits and can access all premium features. Enjoy!"
        img={<OrderCompleteIllustration/>}
        action={
          <Button variant="contained" color="primary" onClick={() => navigate(`/dashboard`)}>
            Go Now
          </Button>
        }
      />
    </Container>
  );
}
