// @mui
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Badge, {badgeClasses} from '@mui/material/Badge';
import LoginIcon from '@mui/icons-material/Login';
// hooks
import {useOffSetTop} from 'src/hooks/use-off-set-top';
import {useResponsive} from 'src/hooks/use-responsive';
import {useAuthContext} from 'src/auth/hooks/use-auth-context';
// theme
import {bgBlur} from 'src/theme/css';
// routes
import {paths} from 'src/routes/paths';
// components
import Logo from 'src/components/logo';
import Label from 'src/components/label';
//
import React from 'react';
import {HEADER} from '../config-layout';
import {navConfig, navConfigMobile} from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
//
import {SettingsButton, HeaderShadow} from '../_common';
import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  const {authenticated} = useAuthContext();

  const handleMailto = () => {
    window.location.href = 'mailto:contact@valuehunter.net?subject=Hi, I have a question.';
  };

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container sx={{height: 1, display: 'flex', alignItems: 'center'}}>
          {/* Desktop */}
          {mdUp && (
            <>
              <Badge
                sx={{
                  [`& .${badgeClasses.badge}`]: {
                    top: 8,
                    right: -16,
                  },
                }}
                badgeContent={
                  <Link
                    href={paths.main.root}
                    target="_blank"
                    rel="noopener"
                    underline="none"
                    sx={{ml: 1}}
                  >
                    {/*
                 <Label color="info" sx={{ textTransform: 'unset', height: 22, px: 0.5 }}>
                  v5.3.0
                </Label>
                */}
                  </Link>
                }
              >
                <Logo/>
              </Badge>
              <Box ml={5}> <NavDesktop offsetTop={offsetTop} data={navConfig}/></Box>
              <Stack alignItems="center" direction={{xs: 'row', md: 'row-reverse'}}>
                <Button variant="contained" onClick={handleMailto}>
                  Contact Us <Iconify width={16} icon="eva:external-link-fill" sx={{ml: 1}}/>
                </Button>

              </Stack>

              <Box sx={{flexGrow: 1}}/>
              {!authenticated && (
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    href={`${paths.auth.jwt.login}`}
                  >
                    Login <LoginIcon sx={{ml: 1, width: 16}}/>
                  </Button>
                  <Button
                    variant="contained"
                    href={paths.auth.jwt.register}
                  >
                    Sign Up
                  </Button>
                </Stack>
              )}
            </>
          )}


          {/* Mobile */}
          {!mdUp &&
            (
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between"
                     sx={{width: '100%'}}>
                <Badge
                  sx={{
                    [`& .${badgeClasses.badge}`]: {
                      top: 8,
                      right: -16,
                    },
                  }}
                  badgeContent={
                    <Link
                      href={paths.main.root}
                      target="_blank"
                      rel="noopener"
                      underline="none"
                      sx={{ml: 1}}
                    >
                      {/*
                 <Label color="info" sx={{ textTransform: 'unset', height: 22, px: 0.5 }}>
                  v5.3.0
                </Label>
                */}
                    </Link>
                  }
                >
                  <Logo/>
                </Badge>
                <Stack direction="row" spacing={1}>
                  <Button variant="contained" onClick={handleMailto}>
                    Contact Us <Iconify width={16} icon="eva:external-link-fill" sx={{ml: 1}}/>
                  </Button>
                  <NavMobile offsetTop={offsetTop} data={navConfigMobile}/>
                </Stack>
              </Stack>
            )
          }

        </Container>
      </Toolbar>

      {
        offsetTop && <HeaderShadow/>
      }
    </AppBar>
  )
    ;
}
