import React, {useCallback, useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios, {endpoints} from "../../utils/axios";
import WatchlistDesktop from "./watchlist-desktop";
import WatchlistMobile from "./watchlist-mobile";
import {useAuthContext} from "../../auth/hooks";
import {gaTimingEvent} from "../../utils/ga";

export default function Watchlist() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [companies, setCompanies] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const {authenticated} = useAuthContext();


  const getWatchedCompanies = useCallback(async () => {
    try {
      setLoading(true);
      const result = await axios.get<any>(`${endpoints.dashboard.watchlist}`);
      setCompanies(result.data);
    } catch (error) {
      console.error("Error fetching top companies:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // track time spent
  useEffect(() => {
    const startTime = Date.now(); // Record start time

    return () => {
      const endTime = Date.now(); // Record end time
      const timeSpentMs = endTime - startTime; // Calculate time spent in milliseconds
      const timeSpentSec = Math.round(timeSpentMs / 1000); // Convert to seconds

      gaTimingEvent("Watchlist", timeSpentSec)
    };
  }, []);

  useEffect(() => {
    if (authenticated) {
      getWatchedCompanies();
    }
  }, [getWatchedCompanies, authenticated]);

  const onAddCompany = async (ticker: string) => {
    try {
      setLoading(true);
      const result = await axios.post<any>(`${endpoints.dashboard.watchlist}/${ticker}`);
      getWatchedCompanies();
    } catch (error) {
      setLoading(false);
      console.error("Error adding company to watchlist:", error);
    }
  }

  const onRemoveCompany = async (ticker: string) => {
    try {
      setLoading(true);
      const result = await axios.delete<any>(`${endpoints.dashboard.watchlist}/${ticker}`);
      getWatchedCompanies();
    } catch (error) {
      setLoading(false);
      console.error("Error removing company from watchlist:", error);
    }
  }

  return (
    <>
      <Typography variant="h5" sx={{mt: 2}}>Watchlist</Typography>
          {!isMobile && <WatchlistDesktop companies={companies} onAddCompany={onAddCompany}
                                          onRemoveCompany={onRemoveCompany} isLoading={loading}/>}
          {isMobile && <WatchlistMobile companies={companies} onAddCompany={onAddCompany}
                                        onRemoveCompany={onRemoveCompany} isLoading={loading}/>}
    </>
  );
}
