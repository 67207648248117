import React from "react";
import Box from "@mui/material/Box";
import PriceChartInternal from "../app-area-installed/price-chart-internal";
import {HistoricalPrice} from "./types";


interface PriceChartProps {
  currencySymbol: string;
  prices: HistoricalPrice[];
}

export default function PriceChart({currencySymbol, prices}: PriceChartProps) {
  const categories = prices.map(price => price.date);
  const data = prices.map(price => [price.date, price.close]);
  // get current year
  const year = new Date().getFullYear();
  const fiveDays = '5d';
  const fiveDaysData = data.slice(0, 5);
  const oneMonth = '1m';
  const oneMonthData = data.slice(0, 21);
  const sixMonths = '6m';
  const sixMonthsData = data.slice(0, 21 * 6);
  const oneYear = '1y';
  const oneYearData = data.slice(0, 21 * 12);

  // get data from the beginning of the year
  const YTD = 'YTD';
  // @ts-ignore
  const YTDData = data.filter(price => price[0].startsWith(year.toString()));
  const fiveYears = '5y';
  const fiveYearsData = data;
  return <Box sx={{width: "100%"}}>
    <PriceChartInternal
      title="Price"
      currencySymbol={currencySymbol}
      chart={{
        series: [
          {
            year: fiveDays,
            data: [
              {
                name: "Price",
                data: fiveDaysData,
              },
            ],
          },
          {
            year: oneMonth,
            data: [
              {
                name: "Price",
                data: oneMonthData,
              },
            ],
          },
          {
            year: sixMonths,
            data: [
              {
                name: "Price",
                data: sixMonthsData,
              },
            ],
          },
          {
            year: YTD,
            data: [
              {
                name: "Price",
                data: YTDData,
              },
            ],
          },
          {
            year: oneYear,
            data: [
              {
                name: "Price",
                data: oneYearData,
              },
            ],
          },
          {
            year: fiveYears,
            data: [
              {
                name: "Price",
                data: fiveYearsData,
              },
            ],
          },
        ],
        options: {
          xaxis: {
            type: "datetime",
          },
        },
      }}
    />
  </Box>;
}
