// @mui
import Box from '@mui/material/Box';

// stripe
import {loadStripe} from "@stripe/stripe-js";

// assets
// components
import React from "react";
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

export default function ManagePlanButton() {

  const handleBillingClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent the default action
    event.preventDefault();

    // Get Stripe.js instance
    const stripe = await stripePromise;

    try {
      const response = await axios.post('/api/create-billing-portal-session');
      const session = response.data;

      // When the Checkout Session is created, redirect to the checkout page
      if (stripe) {
        window.location.href = session.url;
      } else {
        console.error('Stripe.js has not loaded yet.');
        alert('Stripe.js has not loaded yet. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      alert('Failed to start the checkout process. Please try again.');
    }
  };


  return (
    <Box component="span" onClick={handleBillingClick}>
      Manage Plan
    </Box>
  );
}
