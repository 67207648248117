import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Checkbox, FormControl, FormControlLabel, FormGroup} from "@mui/material";

export default function SurveyQuestionMultipleChoice({text, choices, response, setResponse}: {
  text: string,
  choices: { id: number, text: string }[],
  response: any
  setResponse: (response: any) => void
}) {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const newResponse = event.target.checked
      ? [...response, id]
      : response.filter((choiceId: number) => choiceId !== id);

    setResponse(newResponse);
  };

  const renderContent = (
    <Box>
      <Typography variant="h6" gutterBottom>{text}</Typography>
      <Typography variant="body2" gutterBottom sx={{color: 'text.secondary'}}>Choose all that apply</Typography>
      <FormControl component="fieldset">
        <FormGroup>
          {choices.map((choice, index) => (
            <FormControlLabel
              key={choice.id}
              control={
                <Checkbox
                  checked={response.includes(choice.id)}
                  onChange={(event) => handleChange(event, choice.id)}
                  size="medium"
                />
              }
              label={choice.text}
              sx={{textTransform: 'capitalize'}}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  );

  return (
    <>
      {renderContent}
    </>
  );
}
