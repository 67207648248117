import {Helmet} from "react-helmet-async";
// layouts
import {RouteWithHeader} from "../../layouts/custom-header/route-with-header";
import ScreenerLayout from "../../layouts/screener";


export const screenerRoutes = [
  {
    path: 'screener',
    element: (
      <>
        <Helmet>
          <title>ValueHunter: Screener</title>
        </Helmet>
        <RouteWithHeader>
          <ScreenerLayout/>
        </RouteWithHeader>
      </>
    ),
  },
];
