import React from "react";
import {Box, Typography, useMediaQuery, Button} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useTheme} from "@mui/material/styles";
import LoginIcon from "@mui/icons-material/Login";

import {useAuthContext} from "../../auth/hooks";
import {paths} from "../../routes/paths";

type Props = {
  children: React.ReactNode;
  returnTo: string;
  featureDescription: string | undefined;
  imageUrl?: string;
};

export function LoginToAccess({children, returnTo, featureDescription, imageUrl = undefined}: Props) {
  const {authenticated} = useAuthContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  if (authenticated) {
    return <>{children}</>;
  }

  const description = featureDescription || "this feature";

  const renderDesktopWithImage = () => (
    <Box
      component="div"
      sx={{
        position: 'relative',
        display: 'inline-block',
        "&:hover .hoverButtons": {
          opacity: 1,
        },
        py: 5,
        px: 10,
        transition: 'transform 0.3s ease-in-out',
        "&:hover": {
          transform: 'scale(1.03)', // Slightly enlarge on hover
        },
      }}
    >
      <Box
        component="img"
        alt="Company logo"
        src={imageUrl}
        sx={{
          width: '100%',
          height: 'auto',
        }}
      />
      <Box
        className="hoverButtons"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 0,
          transition: 'opacity 0.3s ease-in-out',
          display: 'flex',
          gap: 2,
        }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography variant="body1" color="text.primary" sx={{mb: 1}}>
            Login or signup to get full access to {description}. It is completely free!
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              href={`${paths.auth.jwt.login}?returnTo=${returnTo}`}
            >
              Login <LoginIcon sx={{ml: 1, width: 16}}/>
            </Button>
            <Button
              variant="contained"
              href={paths.auth.jwt.register}
            >
              Signup
            </Button>
          </Stack>
        </Stack>

      </Box>
    </Box>
  );

  const renderDesktopWithoutImage = () => (
    <Stack direction="column" justifyContent="center" alignItems="center" sx={{m: 5}}>
      <Typography variant="body1" color="text.primary" textAlign="center" sx={{mb: 1}}>
        Login or signup to get full access to <strong>{description}</strong>.
      </Typography>
      <Typography variant="body1" color="text.primary" textAlign="center" sx={{mb: 1}}>
         It is completely free!
      </Typography>

            <Box
        component="img"
        alt="Company logo"
        src="/assets/illustrations/login.svg"
        sx={{
          width: {xs: 150, md: 200, lg: 250},
          height: 'auto',
          my: 2
        }}
      />
      <Stack direction="row" spacing={2} sx={{mt: 2}}>
        <Button
          variant="contained"
          href={`${paths.auth.jwt.login}?returnTo=${returnTo}`}
        >
          Login <LoginIcon sx={{ml: 1, width: 16}}/>
        </Button>
        <Button
          variant="outlined"
          href={paths.auth.jwt.register}
        >
          Signup
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <Box position="relative">
      {!isMobile && imageUrl && renderDesktopWithImage()}
      {!isMobile && !imageUrl && renderDesktopWithoutImage()}
      {isMobile && renderDesktopWithoutImage()}
    </Box>
  );
}
