// @mui
import React, {useState} from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Card, {CardProps} from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {ScoreData} from './types';
import ScoreBreakdownProgressElement from './score-breakdown-progress-element';
import {fShortenNumber} from "../../utils/format-number";
import {defaultGrowthScore} from "./constants";
// utils

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  scoreData: ScoreData;
}

type ScoreDataKey = keyof ScoreData;

interface ScoreBreakdownProgressParams {
  onMouseEnter: (e: any) => void;
  onMouseLeave: () => void;
  badLabel: string;
  goodLabel: string;
  value: any;
}

const hoverStyles = {
  transition: 'transform 0.3s ease-in-out',
  "&:hover": {
    cursor: "help",
    transform: 'scale(1.03)' // Slightly enlarge on hover
  },
}

function ScoreBreakdownProgress({
                                  badLabel,
                                  goodLabel,
                                  onMouseEnter,
                                  onMouseLeave,
                                  value
                                }: ScoreBreakdownProgressParams) {

  const theme = useTheme();
  const primaryLight = theme.palette.primary.main;
  const errorLight = theme.palette.error.dark;
  return <Stack
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    sx={hoverStyles}
  >
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{mb: 1}}
    >
      <Box sx={{typography: "overline", color: errorLight, fontSize: 10, width: "112px"}}>{badLabel}</Box>
      <Box sx={{typography: "subtitle1", fontSize: 12}}>{value ? fShortenNumber(value.score) : "N/A"}</Box>
      <Box sx={{typography: "overline", color: primaryLight, fontSize: 10, width: "112px", textAlign: "right"}}>{goodLabel}</Box>
    </Stack>

    <ScoreBreakdownProgressElement score={value ? value.score : defaultGrowthScore}/>
  </Stack>;
}

export default function ScoreBreakdown({title, subheader, scoreData, ...other}: Props) {
  // State for managing Popover and its content
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState<any>(null);

  const popoverContentMapping = {
    price_data: {
      "Price To Earnings": scoreData.price_data.price_to_earnings.toFixed(2),
      "Price To Book": scoreData.price_data.price_to_book.toFixed(2),
      "Price To Free Cash Flow": scoreData.price_data.price_to_free_cashflow.toFixed(2),
      "Enterprise Value To EBITDA": scoreData.price_data.ev_to_ebitda.toFixed(2),
      "Price To Earnings Growth": scoreData.price_data.peg.toFixed(2),
    },
    solvency_data: {
      "Current Ratio": scoreData.solvency_data.current_ratio.toFixed(2),
      "Quick Ratio": scoreData.solvency_data.quick_ratio.toFixed(2),
      "Debt To Equity": scoreData.solvency_data.debt_to_equity.toFixed(2),
      "Debt To Assets": scoreData.solvency_data.debt_to_assets.toFixed(2),
      "Interest Coverage Ratio": scoreData.solvency_data.interest_coverage_ratio.toFixed(2),
      "Piotroski F-Score": scoreData.solvency_data.piotroski_score?.toFixed(2),
    },
    growth_data: {

      "Expected Revenue Growth (1 year)": scoreData.growth_data ? `${scoreData.growth_data.revenue_1y_expected_change.toFixed(2)} %` : "N/A",
      "Expected EBITDA Growth (1 year)": scoreData.growth_data ? `${scoreData.growth_data.ebitda_1y_expected_change.toFixed(2)} %` : "N/A",
      "Expected EPS Growth (1 year)": scoreData.growth_data ? `${scoreData.growth_data.eps_1y_expected_change.toFixed(2)} %` : "N/A",
      "Past Revenue Growth (5 years)": scoreData.growth_data ? `${scoreData.growth_data.revenue_5y_actual_change} %` : "N/A",
      "Past EBITDA Growth (5 years)": scoreData.growth_data ? `${scoreData.growth_data.ebitda_5y_actual_change} %` : "N/A",
      "Past EPS Growth (5 years)": scoreData.growth_data ? `${scoreData.growth_data.eps_5y_actual_change} %` : "N/A",
      "Revenue Growth Consistency (5 years)": scoreData.growth_data ? `${scoreData.growth_data.revenue_growth_consistency} %` : "N/A",
      "EBITDA Growth Consistency (5 years)": scoreData.growth_data ? `${scoreData.growth_data.ebitda_growth_consistency} %` : "N/A",
      "EPS Growth Consistency (5 years)": scoreData.growth_data ? `${scoreData.growth_data.eps_growth_consistency} %` : "N/A",
    },
    efficiency_data: {
      "Return On Capital Employed": `${(scoreData.efficiency_data.return_on_capital_employed * 100).toFixed(2)} %`,
      "Return On Equity": `${(scoreData.efficiency_data.return_on_equity * 100).toFixed(2)} %`,
      "Return On Assets": `${(scoreData.efficiency_data.return_on_assets * 100).toFixed(2)} %`,
      "Operating Profit Margin": `${(scoreData.efficiency_data.operating_profit_margin * 100).toFixed(2)} %`,
      "Net Profit Margin": `${(scoreData.efficiency_data.net_profit_margin * 100).toFixed(2)} %`,
      "Asset Turnover": `${(scoreData.efficiency_data.asset_turnover * 100).toFixed(2)} %`,
    }
  }

  const handlePopoverOpen = (event: any, dataKey: ScoreDataKey) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(popoverContentMapping[dataKey]);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent(null); // Clear the content when popover is closed
  };

  const open = Boolean(anchorEl);
  return (
    <Card {...other} sx={{height: '100%'}}>
      <CardHeader title={title} subheader={subheader}/>

      <Stack spacing={3} sx={{p: 3}}>
        <ScoreBreakdownProgress badLabel="Overvalued"
                                goodLabel="Undervalued"
                                onMouseEnter={(e) => handlePopoverOpen(e, "price_data")}
                                onMouseLeave={handlePopoverClose} value={scoreData.price_data}/>
        <ScoreBreakdownProgress badLabel="Financially Weak"
                                goodLabel="Financially Strong"
                                onMouseEnter={(e) => handlePopoverOpen(e, "solvency_data")}
                                onMouseLeave={handlePopoverClose} value={scoreData.solvency_data}/>
        <ScoreBreakdownProgress badLabel="Inefficient"
                                goodLabel="Efficient"
                                onMouseEnter={(e) => handlePopoverOpen(e, "efficiency_data")}
                                onMouseLeave={handlePopoverClose} value={scoreData.efficiency_data}/>
        <ScoreBreakdownProgress badLabel="Likely to decline"
                                goodLabel="Likely to grow"
                                onMouseEnter={(e) => handlePopoverOpen(e, "growth_data")}
                                onMouseLeave={handlePopoverClose} value={scoreData.growth_data}/>
      </Stack>

      <Popover
        id="mouse-over-popover"
        sx={{pointerEvents: 'none'}}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
      >
        {popoverContent &&
          Object.entries(popoverContent).map(([k, v]) => (
            <Typography key={k} variant="body2" sx={{p: 1}}>
              <>
                <strong>{k}:</strong> {v}
              </>
            </Typography>
          ))
        }
      </Popover>
    </Card>
  );
}
