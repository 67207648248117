import {ChartSeriesType, Financials, FinancialsRow} from "./types";

export const calculateChangePercentage = (current: number, previous: number): number =>
  (current - previous) / Math.abs(previous) * 100

export const formatQuarterlyDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short', day: 'numeric'};
  return new Date(dateString).toLocaleDateString('en-GB', options);
};

export const formatYearlyDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {year: 'numeric'};
  return new Date(dateString).toLocaleDateString('en-GB', options);
};

export const buildIncomeStatementPeriodsData = (financialsLocal: Financials, period: string): FinancialsRow[] => {
  const incomeData = period === 'Quarterly' ? financialsLocal.quarterly.income_statement : financialsLocal.annual.income_statement

  return [
    {
      label: 'Revenue',
      periods_values: incomeData.map((item: any) => item.revenue),
      periods: incomeData.map((item: any) => period === 'Quarterly' ? formatQuarterlyDate(item.date) : formatYearlyDate(item.date)),
    },
    {
      label: 'Net Income',
      periods_values: incomeData.map((item: any) => item.netIncome),
      periods: incomeData.map((item: any) => item.date),
    },
    {
      label: 'Operating Income',
      periods_values: incomeData.map((item: any) => item.operatingIncome),
      periods: incomeData.map((item: any) => item.date),
    },
    {
      label: 'Operating Expenses',
      periods_values: incomeData.map((item: any) => item.operatingExpenses),
      periods: incomeData.map((item: any) => item.date),
    },
    {
      label: 'Operating Ratio',
      periods_values: incomeData.map((item: any) => item.operatingIncomeRatio),
      periods: incomeData.map((item: any) => item.date),
    },
    {
      label: 'EBITDA',
      periods_values: incomeData.map((item: any) => item.ebitda),
      periods: incomeData.map((item: any) => item.date),
    },
    {
      label: 'Earnings Per Share (EPS)',
      periods_values: incomeData.map((item: any) => item.eps),
      periods: incomeData.map((item: any) => item.date),
    }
  ]
}


export const buildChartCategoriesIncomeStatement = (financialsLocal: Financials, period: string): string[] =>
  period === 'Quarterly' ?
    financialsLocal.quarterly.income_statement.map((item: any) => `${item.period} ${item.calendarYear}`)
    : financialsLocal.annual.income_statement.map((item: any) => item.calendarYear)

export const buildChartSeriesIncomeStatement = (financialsLocal: Financials): ChartSeriesType[] =>
  [
    {
      type: 'Quarterly',
      data: [
        {
          name: 'Net Income',
          data: financialsLocal.quarterly.income_statement.map((item: any) => item.netIncome),
        },
        {
          name: 'Revenue',
          data: financialsLocal.quarterly.income_statement.map((item: any) => item.revenue),
        },
      ],
    },
    {
      type: 'Annual',
      data: [
        {
          name: 'Net Income',
          data: financialsLocal.annual.income_statement.map((item: any) => item.netIncome),
        },
        {
          name: 'Revenue',
          data: financialsLocal.annual.income_statement.map((item: any) => item.revenue),
        },
      ],
    },
  ]


export const buildBalanceSheetPeriodsData = (financialsLocal: Financials, period: string): FinancialsRow[] => {
  const balanceSheetData = period === 'Quarterly' ? financialsLocal.quarterly.balance_sheet : financialsLocal.annual.balance_sheet


  // return all balance_sheet fields
  return [
    {
      label: 'Total Assets',
      periods_values: balanceSheetData.map((item: any) => item.totalAssets),
      periods: balanceSheetData.map((item: any) => period === 'Quarterly' ? formatQuarterlyDate(item.date) : formatYearlyDate(item.date)),
    },
    {
      label: 'Total Current Assets',
      periods_values: balanceSheetData.map((item: any) => item.totalCurrentAssets),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Total Non-Current Assets',
      periods_values: balanceSheetData.map((item: any) => item.totalNonCurrentAssets),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Goodwill And Intangible Assets',
      periods_values: balanceSheetData.map((item: any) => item.goodwillAndIntangibleAssets),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Total Liabilities',
      periods_values: balanceSheetData.map((item: any) => item.totalLiabilities),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Total Current Liabilities',
      periods_values: balanceSheetData.map((item: any) => item.totalCurrentLiabilities),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Total Non-Current Liabilities',
      periods_values: balanceSheetData.map((item: any) => item.totalNonCurrentLiabilities),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Total Equity',
      periods_values: balanceSheetData.map((item: any) => item.totalEquity),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Retained Earnings',
      periods_values: balanceSheetData.map((item: any) => item.retainedEarnings),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Total Stockholders Equity',
      periods_values: balanceSheetData.map((item: any) => item.totalStockholdersEquity),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Common Stock',
      periods_values: balanceSheetData.map((item: any) => item.commonStock),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Short Term Investments',
      periods_values: balanceSheetData.map((item: any) => item.shortTermInvestments),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Long Term Investments',
      periods_values: balanceSheetData.map((item: any) => item.longTermInvestments),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Total Investments',
      periods_values: balanceSheetData.map((item: any) => item.totalInvestments),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Short Term Debt',
      periods_values: balanceSheetData.map((item: any) => item.shortTermDebt),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Long Term Debt',
      periods_values: balanceSheetData.map((item: any) => item.longTermDebt),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Net Debt',
      periods_values: balanceSheetData.map((item: any) => item.netDebt),
      periods: balanceSheetData.map((item: any) => item.date),
    },
    {
      label: 'Total Debt',
      periods_values: balanceSheetData.map((item: any) => item.totalDebt),
      periods: balanceSheetData.map((item: any) => item.date),
    },

  ]
}

export const buildChartCategoriesBalanceSheet = (financialsLocal: Financials, period: string): string[] =>
  period === 'Quarterly' ?
    financialsLocal.quarterly.balance_sheet.map((item: any) => `${item.period} ${item.calendarYear}`)
    : financialsLocal.annual.balance_sheet.map((item: any) => item.calendarYear)

export const buildChartSeriesBalanceSheet = (financialsLocal: Financials): ChartSeriesType[] =>
  [
    {
      type: 'Quarterly',
      data: [
        {
          name: 'Total Assets',
          data: financialsLocal.quarterly.balance_sheet.map((item: any) => item.totalAssets),
        },
        {
          name: 'Total Liabilities',
          data: financialsLocal.quarterly.balance_sheet.map((item: any) => item.totalLiabilities),
        },
      ],
    },
    {
      type: 'Annual',
      data: [
        {
          name: 'Total Assets',
          data: financialsLocal.annual.balance_sheet.map((item: any) => item.totalAssets),
        },
        {
          name: 'Total Liabilities',
          data: financialsLocal.annual.balance_sheet.map((item: any) => item.totalLiabilities),
        },
      ],
    },
  ]

export const buildCashFlowPeriodsData = (financialsLocal: Financials, period: string): FinancialsRow[] => {
  const cashFlowData = period === 'Quarterly' ? financialsLocal.quarterly.cash_flow_statement : financialsLocal.annual.cash_flow_statement
  return [
    {
      label: 'Net Income',
      periods_values: cashFlowData.map((item: any) => item.netIncome),
      periods: cashFlowData.map((item: any) => period === 'Quarterly' ? formatQuarterlyDate(item.date) : formatYearlyDate(item.date)),
    },
    {
      label: 'Free Cash Flow',
      periods_values: cashFlowData.map((item: any) => item.freeCashFlow),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Operating Cash Flow',
      periods_values: cashFlowData.map((item: any) => item.operatingCashFlow),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Net Cash Provided By Operating Activities',
      periods_values: cashFlowData.map((item: any) => item.netCashProvidedByOperatingActivities),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Cash At End Of Period',
      periods_values: cashFlowData.map((item: any) => item.cashAtEndOfPeriod),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Capital Expenditure',
      periods_values: cashFlowData.map((item: any) => item.capitalExpenditure),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Net Cash Used For Investing Activities',
      periods_values: cashFlowData.map((item: any) => item.netCashUsedForInvestingActivities),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Depreciation And Amortization',
      periods_values: cashFlowData.map((item: any) => item.depreciationAndAmortization),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Debt Repayment',
      periods_values: cashFlowData.map((item: any) => item.debtRepayment),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Dividends Paid',
      periods_values: cashFlowData.map((item: any) => item.dividendsPaid),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Stock Repurchased',
      periods_values: cashFlowData.map((item: any) => item.commonStockRepurchased),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Inventory',
      periods_values: cashFlowData.map((item: any) => item.inventory),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Accounts Receivables',
      periods_values: cashFlowData.map((item: any) => item.accountsReceivables),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Stock Based Compensation',
      periods_values: cashFlowData.map((item: any) => item.stockBasedCompensation),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Accounts Payables',
      periods_values: cashFlowData.map((item: any) => item.accountsPayables),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Deferred Income Tax',
      periods_values: cashFlowData.map((item: any) => item.deferredIncomeTax),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Other Non Cash Items',
      periods_values: cashFlowData.map((item: any) => item.otherNonCashItems),
      periods: cashFlowData.map((item: any) => item.date),
    },
    {
      label: 'Other Working Capital',
      periods_values: cashFlowData.map((item: any) => item.otherWorkingCapital),
      periods: cashFlowData.map((item: any) => item.date),
    }
  ]
}


export const buildChartCategoriesCashFlow = (financialsLocal: Financials, period: string): string[] =>
  period === 'Quarterly' ?
    financialsLocal.quarterly.cash_flow_statement.map((item: any) => `${item.period} ${item.calendarYear}`)
    : financialsLocal.annual.cash_flow_statement.map((item: any) => item.calendarYear)

export const buildChartSeriesCashFlow = (financialsLocal: Financials): ChartSeriesType[] =>
  [
    {
      type: 'Quarterly',
      data: [
        {
          name: 'Net Income',
          data: financialsLocal.quarterly.cash_flow_statement.map((item: any) => item.netIncome),
        },
        {
          name: 'Free Cash Flow',
          data: financialsLocal.quarterly.cash_flow_statement.map((item: any) => item.freeCashFlow),
        },
        {
          name: 'Net Change In Cash',
          data: financialsLocal.quarterly.cash_flow_statement.map((item: any) => item.netChangeInCash),
        }
      ],
    },
    {
      type: 'Annual',
      data: [
        {
          name: 'Net Income',
          data: financialsLocal.annual.cash_flow_statement.map((item: any) => item.netIncome),
        },
        {
          name: 'Free Cash Flow',
          data: financialsLocal.annual.cash_flow_statement.map((item: any) => item.freeCashFlow),
        },
        {
          name: 'Net Change In Cash',
          data: financialsLocal.annual.cash_flow_statement.map((item: any) => item.netChangeInCash),
        }
      ],
    },
  ]
