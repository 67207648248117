import React, {useState} from 'react';
// @mui
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import ReactMarkdown from "react-markdown";

import {useAuthContext} from "../../auth/hooks";


export default function CompanyFinancialStrengthAiAnalysis({overallScore, financialStrengthAnalysis}: {
  overallScore: number,
  financialStrengthAnalysis: any
}) {
  const {authenticated} = useAuthContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const primaryMain = theme.palette.primary.main;
  const primaryLight = theme.palette.primary.light;
  const errorLight = theme.palette.error.light;
  const errorMain = theme.palette.error.main;
  const grey900 = theme.palette.grey[900];

  const verdictValueToColor = (value: number) => {
    switch (value) {
      case 5:
        return primaryMain;
      case 4:
        return primaryLight;
      case 3:
        return grey900;
      case 2:
        return errorLight;
      case 1:
        return errorMain;
      default:
        return grey900;
    }
  }
  const hoverStyles = {
    transition: 'transform 0.3s ease-in-out',
    "&:hover": {
      cursor: "help",
      transform: 'scale(1.02)' // Slightly enlarge on hover
    },
  }


  if (!authenticated) {
    return (
      <Box/>
    );
  }
  return (
    <Box>
      {/*
      <Typography variant="body1"

      >

<Box
          onMouseEnter={(e) => handlePopoverOpen(e)}
          onMouseLeave={handlePopoverClose}
          sx={{
            ...hoverStyles,
          }}
        >
          <b>Verdict:</b> <span
          style={{color: verdictValueToColor(financialStrengthAnalysis.overall_score)}}>{financialStrengthAnalysis.overall_score}</span>
        </Box>
      </Typography>
        */}
            <Typography variant="h6" sx={{color: 'text.primary'}}>
        Financial Strength Score: {overallScore} (out of 5)
      </Typography>
      <Typography variant="body2" sx={{
        color: 'text.primary',
      }}>
        <ReactMarkdown>
          {financialStrengthAnalysis.overall_financial_strength}
        </ReactMarkdown>
      </Typography>
      <Typography variant="h6" sx={{color: 'text.primary'}}>
        Liquidity
      </Typography>
      <Typography variant="body2" sx={{
        color: 'text.primary',
      }}>
        <ReactMarkdown>
          {financialStrengthAnalysis.liquidity}
        </ReactMarkdown>
      </Typography>
      <Typography variant="h6" sx={{color: 'text.primary'}}>
        Leverage
      </Typography>
      <Typography variant="body2" sx={{
        color: 'text.primary',
      }}>
        <ReactMarkdown>
          {financialStrengthAnalysis.leverage}
        </ReactMarkdown>
      </Typography>
      <Typography variant="h6" sx={{color: 'text.primary'}}>
        Piotroski F-Score
      </Typography>
      <Typography variant="body2" sx={{
        color: 'text.primary',
      }}>
        <ReactMarkdown>
          {financialStrengthAnalysis.piotroski_score}
        </ReactMarkdown>
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'left'}}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
        sx={{pointerEvents: 'none'}}
        slotProps={{
          paper: {style: {width: 250}},
        }}
      >
        <Typography variant="body2" sx={{p: 0.5, fontSize: 12}}>
          Could be one of:
          <br/> - Strongly Undervalued
          <br/> - Undervalued
          <br/> - Fairly Valued
          <br/> - Overvalued
          <br/> - Strongly Overvalued
        </Typography>
      </Popover>
    </Box>
  );
}
