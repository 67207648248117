import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";

export default function SurveyQuestionSingleChoice({text, choices, response, setResponse}: {
  text: string,
  choices: { id: number, text: string }[],
  response: any
  setResponse: (response: any) => void
}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setResponse([parseInt((event.target as HTMLInputElement).value as string, 10)]);
  };

  const renderContent = (
    <Box>
      <Typography variant="h6" gutterBottom>{text}</Typography>
      <FormControl component="fieldset">
        <RadioGroup value={response} onChange={handleChange}>
          {choices.map((choice) => (
            <FormControlLabel
              key={choice.id}
              value={choice.id}
              control={<Radio size="medium"/>}
              label={choice.text}
              sx={{textTransform: 'capitalize'}}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );

  return (
    <>
      {renderContent}
    </>
  );
}
