import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {paths} from 'src/routes/paths';
import LoginIcon from "@mui/icons-material/Login";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useLocation} from "react-router-dom";

export default function LoginRegisterDialog({dialogOpen, onClose}: { dialogOpen: boolean, onClose: () => void }) {
  // const openDialog = () => {
  //   setDialogOpen(true);
  //   ReactGA.event("AlgoInfo", {
  //     category: "Algorithm",
  //     action: "Open algorithm info",
  //   });
  // }

  const location = useLocation();
  // set returnTo to the current location
  const returnTo = location.pathname + location.search;

  const renderContent = (
      <Stack direction="column" spacing={2} justifyContent="center" sx={{p: 4}}>
        <Typography variant="body1">
          It is completely free to use ValueHunter, but you need to login or sign up to continue.
        </Typography>
        <Button
          variant="contained"
          href={`${paths.auth.jwt.login}?returnTo=${returnTo}`}
        >
          Login <LoginIcon sx={{ml: 1, width: 16}}/>
        </Button>
        <Button
          variant="contained"
          href={`${paths.auth.jwt.register}?returnTo=${returnTo}`}
        >
          Sign Up
        </Button>
      </Stack>
  );
  return (
    <Dialog open={dialogOpen} maxWidth="xs" fullWidth>
      <DialogTitle>Please Login or Sign Up
           <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{position: 'absolute', right: 14, top: 8}}
      >
        <CloseIcon/>
      </IconButton>
      </DialogTitle>
      <DialogContent>
        {renderContent}
      </DialogContent>
    </Dialog>
  );
}
