// @mui
import React, {useCallback, useState} from "react";
import {Box, Card, Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ButtonBase from "@mui/material/ButtonBase";

import Chart, {useChart} from 'src/components/chart';
import CustomPopover, {usePopover} from "../custom-popover";
import Iconify from "../iconify";

interface Peer {
  ticker: string;
  companyName: string;
  sector: string;
  industry: string;
  priceToEarnings: number;
  priceToBook: number;
  priceToSales: number;
  priceToFreeCashFlows: number;
}

export interface PeersData {
  priceToEarnings: {
    mean: number;
    peers: Peer[]
  },
  priceToBook: {
    mean: number;
    peers: Peer[]
  },
  priceToSales: {
    mean: number;
    peers: Peer[]
  },
  priceToFreeCashFlows: {
    mean: number;
    peers: Peer[]
  }

}

export interface PeersProps {
  ticker: string | undefined;
  peersData: PeersData;
}

export default function CompanyValuationPeersComparison({ticker, peersData}: PeersProps) {
  const theme = useTheme();
  const primaryLight = theme.palette.primary.light;
  const primaryMain = theme.palette.primary.main;
  const grey400 = theme.palette.grey[400];

  const popover = usePopover();
  const [currentSeriesKey, setCurrentSeriesKey] = useState('P/E Ratio');
  const [currentSeriesField, setCurrentSeriesField] = useState('priceToEarnings');
  const [currentSeriesValue, setCurrentSeriesValue] = useState(peersData.priceToEarnings.peers.find((peer) => peer.ticker === ticker)?.priceToEarnings);
  const [currentMean, setCurrentMean] = useState(peersData.priceToEarnings.mean);
  const [barsColors, setBarsColors] = useState<string[]>(peersData.priceToEarnings.peers.map((peer) => peer.ticker === ticker ? primaryMain : primaryLight));

  const handleChangeSeries = useCallback((newValue: string) => {
    popover.onClose();
    const seriesKeyToField: { [key: string]: string } = {
      "P/E Ratio": 'priceToEarnings',
      "P/B Ratio": 'priceToBook',
      "P/Sales Ratio": 'priceToSales',
      "P/FCF Ratio": 'priceToFreeCashFlows',
    }
    setCurrentSeriesKey(newValue);
    setCurrentSeriesField(seriesKeyToField[newValue]);
    // @ts-ignore
    setCurrentSeriesValue(peersData[seriesKeyToField[newValue]].peers.find((peer: Peer) => peer.ticker === ticker)[seriesKeyToField[newValue]]);
    // @ts-ignore
    setBarsColors(peersData[seriesKeyToField[newValue]].peers.map((peer: Peer) => peer.ticker === ticker ? primaryMain : primaryLight));
    // @ts-ignore
    setCurrentMean(peersData[seriesKeyToField[newValue]].mean);

  }, [popover, setCurrentSeriesKey, setCurrentSeriesField, ticker, peersData, primaryMain, primaryLight]);

  const peersChart = useChart({
    series: [
      {
        name: currentSeriesKey,
        // @ts-ignore
        data: peersData[currentSeriesField].peers.map((peer) => peer[currentSeriesField]),
      },
    ],
    colors: barsColors,
    annotations: {
      yaxis: [
        {
          y: currentMean,
          borderColor: grey400,
          borderWidth: 2,
          opacity: 0.2,
          fillColor: '#B3F7CA',
          label: {
            position: 'right',
            style: {
              fontSize: '14px',
            },
            text: `Avg: ${currentMean}`
          }
        }
      ]
    },
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        borderRadius: 5,
        dataLabels: {
          position: 'top',
        },
        columnWidth: '95%',
      }
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '13px',
        colors: ["#304758"]
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      // @ts-ignore
      categories: peersData[currentSeriesField].peers.map((peer: Peer) => peer.ticker),
      labels: {
        style: {
          fontSize: '12px'
        }
      },
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
    },
    tooltip: {
      x: {
        formatter(val: number) {
          const valStr = val.toString();
          // @ts-ignore
          return peersData[currentSeriesField].peers.find((peer) => peer.ticker === valStr)?.companyName || valStr;
        }
      }
    },
    yaxis: {
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        style: {
          fontSize: '12px'
        },
      },
    }
  });

  if (!peersData.priceToEarnings.peers.length) {
    return (
      <Box sx={{mb: 3}}>
        <Typography variant="h6" sx={{color: 'text.primary'}}>
          Peers Comparison
        </Typography>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          No peers data available
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <Box>
        <Card>
          <CardHeader
            title="Peers Comparison"
            subheader={
              <Typography
                variant="body2"
                component="span"
                color="text.secondary"

              >
                {currentSeriesKey}: <b>{currentSeriesValue}</b>
              </Typography>
            }
            action={
              <ButtonBase
                onClick={popover.onOpen}
                sx={{
                  pl: 1,
                  py: 0.5,
                  pr: 0.5,
                  borderRadius: 1,
                  typography: 'subtitle2',
                  bgcolor: 'background.neutral',
                }}
              >
                {currentSeriesKey}

                <Iconify
                  width={16}
                  icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                  sx={{ml: 0.5}}
                />
              </ButtonBase>
            }
          />
          <Chart
            type="bar"
            dir="ltr"
            series={peersChart.series}
            options={peersChart}
            height={300}
          />
        </Card>
      </Box>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{width: 140}}>
        {["P/E Ratio", "P/B Ratio", "P/Sales Ratio", "P/FCF Ratio"].map((key) => (
          <MenuItem
            key={key}
            selected={key === currentSeriesKey}
            onClick={() => handleChangeSeries(key)}
          >
            {key}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
