// @mui
import React from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";


import {fPercent, fShortenNumber} from "../../utils/format-number";
import {FinancialsRow} from "./types";
import Chart from "../chart/chart";
import {useChart} from "../chart";
import {calculateChangePercentage} from "./financials-utils";

interface AdditionalFinancialsProps2 {
  additionalFinancials: FinancialsRow[];
}

const stickyStyle = {
  position: "sticky",
  left: 0,
  borderRight: "1px solid",
  borderColor: "background.neutral",
  zIndex: 100,
};


export default function AdditionalFinancials({additionalFinancials}: AdditionalFinancialsProps2) {
  const chartOptions = useChart({
      chart: {
          type: 'bar',
          sparkline: {
              enabled: true
          }
      },
      plotOptions: {
          bar: {
              horizontal: false,
              columnWidth: '80%',
              borderRadius: 1
          },
      },
      xaxis: {
          labels: {
              show: false // Disable x-axis labels
          },
          axisBorder: {
              show: false
          },
          axisTicks: {
              show: false
          }
      },
      yaxis: {
          labels: {
              show: false // Disable y-axis labels
          }
      },
      grid: {
          show: false // Optionally, hide the grid as well
      },
      dataLabels: {
          enabled: false // Ensure data labels are disabled
      },
      tooltip: {
          enabled: false // Disable tooltips
      }
  });
  return (
    additionalFinancials.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 650, tableLayout: "fixed"}} aria-label="simple table">
            <colgroup>
              <col style={{width: '20%'}}/>
              <col style={{width: '13%'}}/>
              <col style={{width: '13%'}}/>
              <col style={{width: '13%'}}/>
              <col style={{width: '13%'}}/>
              <col style={{width: '13%'}}/>
              <col style={{width: '15%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell sx={{bgcolor: 'background.neutral', ...stickyStyle}}>Name</TableCell>
                {additionalFinancials[0]?.periods.map((period) => (
                  <TableCell align="right" key={period}>{period}</TableCell>
                ))}
                <TableCell align="right" sx={{bgcolor: 'background.neutral', ...stickyStyle}}>5Y Change</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {additionalFinancials.map((row) => (
                <TableRow
                  key={row.label}
                  sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                  <TableCell component="th" scope="row" sx={{bgcolor: 'background.default', ...stickyStyle}}>
                    {row.label}
                  </TableCell>
                  {row.periods_values.map((value_for_period, index) => {
                    const previousValue = index > 0 ? row.periods_values[index - 1] : undefined;
                    const changePercentage = value_for_period && previousValue ? calculateChangePercentage(value_for_period, previousValue) : undefined;
                    // eslint-disable-next-line no-nested-ternary
                    const textColor = changePercentage !== undefined ? changePercentage >= 0 ? 'green' : 'red' : 'text.primary';
                    let changePercentageStr = ''
                    if (changePercentage !== undefined) {
                      changePercentageStr = fPercent(changePercentage);
                    }
                    return (
                      <TableCell align="right" key={index}>
                        <Stack>
                          <Box>
                            {value_for_period ? fShortenNumber(value_for_period) : '-'}
                          </Box>
                          <Box sx={{fontSize: 12, color: textColor}}>
                            {changePercentageStr}
                          </Box>
                        </Stack>
                      </TableCell>
                    );
                  })}

                  {/* small chart */}
                  <TableCell align="right" sx={{p: 1}}>
                    {
                      row.periods_values.some(item => item) && chartOptions ?
                      <Chart type="bar" series={[{name: '', data: row.periods_values}]} options={chartOptions} height={50}/> : ''
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
      : null
  );
}
