// layouts
import {RouteWithHeader} from "../../layouts/custom-header/route-with-header";
import AnnualReportAnalysis from "../../components/documents_analysis/annual_report_analysis";
import {LoginToAccess} from "../../components/utility_components/login-to-access";


export const analysisRoutes = [
  {
    path: 'document-analysis/:ticker?',
    element: (

      <RouteWithHeader>
        <LoginToAccess returnTo="/document-analysis" featureDescription="document analysis">
          <AnnualReportAnalysis/>
        </LoginToAccess>
      </RouteWithHeader>
    ),
  },
];
