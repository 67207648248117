import React from "react";
import Stack from "@mui/material/Stack";
import {useTheme} from "@mui/material/styles";
import Chart from "../chart/chart";
import {useChart} from "../chart";


// make width and height optional
export default function PriceChartMinimal({prices, width = 130, height = 90}: {
  prices: [][],
  width?: any,
  height?: any
}) {

  const chartOptions = useChart({
    chart: {
      type: 'area',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.6,
        opacityTo: 0,
        stops: [0, 90, 100]
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      }
    },
    yaxis: {
      labels: {
        show: false,
      }
    },
    grid: {
      show: false,
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topRight',
        offsetY: -50,
      }
    },
  });

  const theme = useTheme();

  if (prices === undefined || prices.length <= 0) return null;

  // @ts-ignore
  const isPriceIncreasing = prices[0][1] > prices[prices.length - 1][1];
  const chartColor = isPriceIncreasing ? theme.palette.success.light : theme.palette.error.main;

  return (
    <Stack direction="row" justifyContent="center">

      <Chart
        dir="ltr"
        type="area"
        options={{
          ...chartOptions,
          stroke: {
            width: 3,
            curve: 'monotoneCubic',
            colors: [chartColor]
          }
        }}
        series={[{
          "name": "Price",
          data: prices
        }]}
        width={width}
        height={height}
      />
    </Stack>
  );
}
