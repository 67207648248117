import {Helmet} from "react-helmet-async";
// layouts
import {RouteWithHeader} from "../../layouts/custom-header/route-with-header";
import PricingView from "../../sections/pricing/view";
import SubscriptionSuccess from "../../sections/subscriptions/subscription-success";


export const subscriptionsRoutes = [
  {
    path: 'plans',
    element: (
      <>
        <Helmet>
          <title>ValueHunter: Pricing</title>
        </Helmet>
        <RouteWithHeader>
          <PricingView/>
        </RouteWithHeader>
      </>
    ),
  },
  {
    path: 'subscription-success',
    element: (
      <>
        <Helmet>
          <title>ValueHunter: Subscription Success</title>
        </Helmet>
        <RouteWithHeader>
          <SubscriptionSuccess/>
        </RouteWithHeader>
      </>
    ),
  },
];
